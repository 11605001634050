import React from 'react';

export default class PaymentSuccessInfo extends React.Component {
	render() {
		return (
			<div className='full-bg padded grey-bg flex'>
				<div className="flex row padded center-xs">
					<div className="col-xs-16 col-lg-6 padded">
						<div className="ui segments">
							<div className="ui segment inverted flex middle-xs">
								ขอบคุณที่ชำระค่าบริการ
								<div className="pull-bottom flex center-xs">
									<div className="triangle-bottom"/>
								</div>
							</div>
							<div className="ui segment">
								อยู่ระหว่างการดำเนินการ
							</div>
							<div className="ui segment secondary">
								<span className="black-font md-font">ข้อมูลการทำรายการ</span>
							</div>
							<div className="ui segment">
								<div className="row">
									<span className="black-font md-font">เลขที่ทำรายการ :</span>
									<div className="right floated">
										<span className="black-font md-font">000000000</span>
									</div>
								</div>
								<div className="ui divider"/>
								<div className="row">
									<span className="black-font md-font">วันที่ชำระเงิน :</span>
									<div className="right floated">
										<span className="black-font md-font">2562-07-09 11:26:55</span>
									</div>
								</div>
								<div className="ui divider"/>
								<div className="row">
									<span className="black-font md-font">บริการ :</span>
									<div className="right floated">
										<span className="black-font md-font">บริการ</span>
									</div>
								</div>
								<div className="ui divider"/>
								<div className="row">
									<span className="black-font md-font">หมายเลขบริการ :</span>
									<div className="right floated">
										<span className="black-font md-font">หมายเลขบริการ</span>
									</div>
								</div>
								<div className="ui divider"/>
								<div className="row">
									<span className="black-font md-font">ยอดชำระ :</span>
									<div className="right floated">
										<div className="segment-price">
											<span className="sub-title">฿ </span>
											<span className="title">545</span>
											<span className="sub-title">.00</span>
										</div>
									</div>
								</div>
							</div>
							<div className="ui segment">
								<div className="row center-xs">
									WETV ได้รับยอดของคุณแล้ว <br/>
									โดยจะอัพเดทถายใน 15 นาที
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}