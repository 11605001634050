/* eslint-disable array-callback-return */
import React from 'react';
import Header from "./Header";
import SaleOrderTabs from './SaleOrderTabs';
import Helper from './Helper';
// import {Global2} from './component/Global';
import EditEmail from './modal_form/EditEmai';
import VerifyEmail from './modal_form/VerifyEmail';
import ChangePhoneNumber from './modal_form/ChangePhoneNumber';
import ChangePassword from './modal_form/ChangePassword';
import AddEmail from './modal_form/AddEmail';

export default class SaleOrderItemMonthly extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			relationship: {},
			relationship_account: {
				relationship: {
					name: 'กำลังโหลด...',
				}
			},
			register_success_messages: window.Global.getCookie('register_success_messages')
			&& window.Global.getCookie('register_success_messages') !== "" ? JSON.parse(window.Global.getCookie('register_success_messages')) : false,
			// relationship_account: Global2.getMyProfile(),
			selected_items: [],
			amount: 0.00,
			sale_order_contacts: [],
			isLoading: true,
			token: window.Global.getAllQueryStrings().token || '',
			url: '',
		};
		// this.state.item_list = [];
	}

	componentDidMount() {
		window.Global.createCookie('register_success_messages', '', 365);
		this.updateUrl();
		this.fetch();
	};

	fetch() {
		window.Global.ajax({
			method: 'GET',
			url: window.host + '/api/v1/e-commerce/relationship/payment-summary',
			data: {
				// token: this.state.token,
				// password: localStorage.getItem('password'),
			},
			done: (response) => {
				if (response.error) {
					window.location = '/?token=' + this.state.token;
				}
				if (!response.success) {
					return false;
				}
				let sale_order_contacts = [...response.sale_order_contacts];
				response.sale_order_contacts.map((sale_order_contact, sale_order_contacts_i) => {
					let check_price = 0;
					sale_order_contact.sale_order_contact_items.map((_sale_order_contact_item, _sale_order_contact_items_i) => {
						if (_sale_order_contact_item.status_paid === "over_due" || _sale_order_contact_item.status_paid === 'waiting_to_approve_payment_by_bank_transfer_draft') {
							check_price = check_price + parseFloat(_sale_order_contact_item.data.total_net_price);
						}
					});
					if (check_price > 0) {
						sale_order_contacts[sale_order_contacts_i].checked = true;
						let total_net_price = 0;
						sale_order_contact.sale_order_contact_items.map((sale_order_contact_item, sale_order_contact_items_i) => {
							if (sale_order_contact_item.status_paid === 'over_due'
								|| sale_order_contact_item.status_paid === 'waiting_to_approve_payment_by_bank_transfer_draft') {
								sale_order_contacts[sale_order_contacts_i].sale_order_contact_items[sale_order_contact_items_i].checked = true;
							}
							if (sale_order_contacts[sale_order_contacts_i].sale_order_contact_items[sale_order_contact_items_i].checked === true
								&& (sale_order_contacts[sale_order_contacts_i].sale_order_contact_items[sale_order_contact_items_i].status_paid === "over_due"
									|| sale_order_contacts[sale_order_contacts_i].sale_order_contact_items[sale_order_contact_items_i].status_paid === "waiting_to_approve_payment_by_bank_transfer_draft")) {
								total_net_price = total_net_price + parseFloat(sale_order_contacts[sale_order_contacts_i].sale_order_contact_items[sale_order_contact_items_i].amount);
							}
						})
					}
				});
				this.setState({
					// relationship: response.relationship_account.relationship,
					relationship_account: response.relationship_account,
					amount: this.summaryAmount(Helper.sumTotalPayment(sale_order_contacts).selected_items),
					selected_items: Helper.sumTotalPayment(sale_order_contacts).selected_items,
					sale_order_contacts: Helper.sumTotalPayment(sale_order_contacts).sale_order_contacts,
					isLoading: false,
				}, () => {
					// localStorage.setItem('selected_items', JSON.stringify(this.state.selected_items));
					this.updateUrl();
				});
			}
		});
	}

	summaryAmount(selected_items) {
		let amount = 0;
		if (selected_items.length > 0) {
			selected_items.map((selected_item) => {
				amount = amount + parseFloat(selected_item.total_net_price);
			});
		}
		return amount;
	}


	updateUrl = () => {
		let selectedItems = [];
		this.state.selected_items.map((selected_item) => {
			selectedItems.push(selected_item.id);
		});
		if (selectedItems.length === 0) {
			this.setState({
				url: ''
			});
			return;
		}
		let new_url = window.Global.getQueryStringLinkFromObject({
			amount: this.state.amount,
			route_id: selectedItems,
			token: this.state.token,
		}, "/payment-method");
		this.setState({
			url: new_url
		})
	};

	checkPayAmount = () => {
		// console.log(this.state);
		if (this.state.url) {
			window.location.href = window.location.origin + this.state.url;
			return;
		}
		window.Global.messageTop({
			node: window.$('#root'),
			type: 'negative',
			message: "เลือกรายการชำระเงิน",
			delay: 3000,
		});
		window.$(this.pay_btn).removeClass('loading');
	};

	render() {
		console.log(this.state.register_success_messages);
		return (
			<div className='full-bg grey-bg flex'>
				<Header/>
				<div className="header-customer-title flex ">
					<div className="flex row center-xs padded">
						<div className="col-xs-16 col-lg-6 padded">
							{
								this.state.register_success_messages ?
									<div className={"ui info message"}>
										<ul className={"list"}>
											<li>{this.state.register_success_messages.message}</li>
										</ul>
									</div>
									: null
							}
							<div className={"ui segment flex row"}>
								<div>
                                    <span className="font-bold" style={{color: '#000'}}>
                                        {this.state.relationship_account.relationship.name}
                                    </span>
									{
										this.state.relationship_account && this.state.relationship_account.email ?
											<div className={"flex row wrap"}>
												<div style={{color: '#000', fontSize: '0.9rem', marginRight: 5}}>
													{this.state.relationship_account.email}
												</div>
												{
													this.state.relationship_account.is_verify_email ?
														<div className={"link"}
														     style={{fontSize: '0.9rem'}}
														     onClick={() => {
															     window.Global.reactToDom(
																     window.$('body'),
																     <EditEmail
																	     email={this.state.relationship_account.email}
																	     onChanged={() => {
																		     // console.log("Changed");?
																	     }}
																     />
															     )
														     }}

														>
															แก้ไขอีเมล
														</div>
														: null
												}
												{
													!this.state.relationship_account.is_verify_email ?
														<div className={"link"}
														     style={{fontSize: '0.9rem'}}
														     onClick={() => {
															     window.Global.reactToDom(
																     window.$('body'),
																     <VerifyEmail/>
															     )
														     }}

														>
															ยืนยันอีเมล
														</div>
														: null
												}
											</div>
											:
											!this.state.isLoading ?
												<div>
                                                <span className={"link"}
                                                      style={{fontSize: '0.9rem'}}
                                                      onClick={() => {
	                                                      window.Global.reactToDom(
		                                                      window.$('body'),
		                                                      <AddEmail
			                                                      title={
				                                                      (this.state.relationship_account
					                                                      && this.state.relationship_account.email
					                                                      && !this.state.relationship_account.is_verify_email) ?
					                                                      'แก้ไขอีเมล์' : false
			                                                      }
			                                                      email={this.state.relationship_account.email}
			                                                      onChanged={(relationship_account) => {
				                                                      this.setState({
					                                                      relationship_account: relationship_account,
				                                                      })
			                                                      }}
			                                                      onSuccess={() => {
				                                                      this.fetch();
			                                                      }}
		                                                      />
	                                                      )
                                                      }}
                                                >
                                                    {
	                                                    (this.state.relationship_account
		                                                    && !this.state.relationship_account.email) ?
		                                                    'เพิ่มอีเมล์'
		                                                    :
		                                                    'แก้ไขอีเมล์'
                                                    }

                                                </span>
												</div>
												: null
									}
								</div>
								{
									!this.state.isLoading ?
										<div className={"right floated"} style={{textAlign: 'right'}}>
											{
												this.state.relationship_account.phone_number ?
													<span
														style={{
															color: '#000',
															fontSize: '0.9rem'
														}}>{this.state.relationship_account.phone_number.replace('+66', 0)}</span>
													: <span
														style={{
															color: '#000',
															fontSize: '0.9rem'
														}}>ยังไม่มีเบอร์โทรศัพท์</span>
											}
											<div className={"flex row wrap"}>
												<span className={"link"}
												      style={{marginRight: 5, fontSize: '0.9rem'}}
												      onClick={() => {
													      window.Global.reactToDom(
														      window.$('body'),
														      <ChangePhoneNumber
															      old_phone_number={this.state.relationship_account.phone_number.replace('+66', 0)}
															      onSuccess={() => {
																      this.fetch();
															      }}
														      />
													      )
												      }}

												>
													{
														this.state.relationship_account.phone_number ?
															"แก้ไขเบอร์โทร"
															:
															"เพิ่มเบอร์โทรศัพท์"
													}
                                                        </span>
												<span style={{color: '#f4f4f4'}}>|</span>
												<span className={"link"}
												      style={{marginLeft: 5, fontSize: '0.9rem'}}
												      onClick={() => {
													      window.Global.reactToDom(
														      window.$('body'),
														      <ChangePassword
															      old_phone_number={this.state.relationship_account.phone_number.replace('+66', 0)}
															      onSuccess={() => {
																      this.fetch();
															      }}
														      />
													      )
												      }}
												>
													แก้ไขรหัสผ่าน
		                                        </span>
											</div>

										</div>
										: null
								}
							</div>
						</div>
					</div>
				</div>

				<div className="flex row center-xs ">
					<div className="col-xs-16 col-lg-6 padded item-monthly-list-segment push-top">
						<div className={"ui segments"}>

							<div className="ui segment" style={{paddingTop: 0}}>
								{/*TITLE*/}
								<div className="row center-xs middle-xs">
									<div className="item-monthly-list-title">
										บิลรายเดือน
									</div>
								</div>
								<div className="flex row wrap form-group middle-xs">
									<div className="flex">
										ยอดรวม
										<div>
											<span>฿ </span>
											<span className="font-bold"
											      style={{fontSize: '1.7rem'}}>
											{this.state.amount.format(2, 3, {pretty: false}).toString().slice(0, -3)}
										</span>
											<span>{this.state.amount.fraction(2, 3, {pretty: false})} </span>
										</div>
									</div>
									<div className="right floated">
										<button className="ui button wetv-btn oval btn-lg"
										        ref={(ref) => {
											        this.pay_btn = ref;
										        }}
										        onClick={() => {
											        window.$(this.pay_btn).addClass('loading');
											        this.checkPayAmount()
										        }}>
											จ่าย
										</button>
									</div>
								</div>
								{/*ITEMS LIST*/}
								{
									this.state.sale_order_contacts.length > 0 ?
										this.state.sale_order_contacts.map((item, i) => {
											return (
												<div
													className={i === 0 ? "custom-segment-space active" : "custom-segment-space"}
													key={i}
													ref={(ref) => {
														this['custom-segment-' + i] = ref
													}}

												>
													<div
														className={(() => {
															let callClass = "ui segment custom-segment no-padded no-margin";
															// callClass = callClass + " success";
															callClass = callClass + " normal";
															return callClass;
														})()}

													>
														<div className="row"
														     style={{position: 'relative'}}>
															{/*ICON*/}
															<div className="segment-icon">
																<div
																	className="segment-icon-bg  center-xs middle-xs flex">
																	<i className={(() => {
																		let callClass = "";
																		callClass = callClass + "fas fa-shopping-bag";
																		// callClass = callClass + "fas fa-file-invoice-dollar";
																		return callClass;
																	})()}/>
																</div>
															</div>
															{/*DETAIL*/}
															<label className="segment-details" htmlFor={"checkbox" + i}>
																<div className="row wrap flex">
																	<div className="flex">
																		<div className="font-bold">{item.title}</div>
																		<div
																			className="status">{item.status_paid_text}</div>
																	</div>
																	<div className="right floated flex row block-after">
																		<div className="segment-price">
																			<span className="sub-title">฿ </span>
																			<span className="title">
																			{item.total_net_price.format(2, 3, {pretty: false}).toString().slice(0, -3)}
																		</span>
																			<span
																				className="sub-title">{item.total_net_price.fraction(2, 3, {pretty: false})}</span>
																		</div>
																		<div className="ui checkbox"
																		     ref={(ref) => {
																			     this['checkbox-' + item.id] = ref;
																			     window.$(ref).checkbox();
																		     }}
																		>
																			<input type="checkbox"
																			       id={"checkbox" + i}
																			       name={"checkbox" + i}
																			       onChange={(e) => {
																				       let checked = e.target.checked;
																				       let state_items = [...this.state.sale_order_contacts];
																				       this.state.sale_order_contacts.map((item_list, item_list_index) => {
																					       if (i === item_list_index) {
																						       state_items[item_list_index].checked = checked;
																						       item_list.sale_order_contact_items.map((sale_order_contact_item, sale_order_contact_item_i) => {
																							       if (sale_order_contact_item.status_paid === 'over_due'
																								       || sale_order_contact_item.status_paid === 'waiting_to_approve_payment_by_bank_transfer_draft'
																								       || (sale_order_contact_item.payment_by_bank_transfer_draft
																									       && sale_order_contact_item.payment_by_bank_transfer_draft.status === 'rejected')) {
																								       state_items[item_list_index].sale_order_contact_items[sale_order_contact_item_i].checked = checked;
																							       }
																						       });
																					       }
																				       });
																				       this.setState({
																					       amount: this.summaryAmount(Helper.sumTotalPayment(state_items).selected_items),
																					       selected_items: Helper.sumTotalPayment(state_items).selected_items,
																					       sale_order_contacts: Helper.sumTotalPayment(state_items).sale_order_contacts,
																				       }, () => {
																					       // localStorage.setItem('selected_items', JSON.stringify(this.state.selected_items));
																					       this.updateUrl();
																				       });
																			       }}
																			       disabled={
																				       item.status_paid === "complete"
																				       || item.status_paid === "waiting_to_approve_payment_by_bank_transfer_draft"
																			       }
																			       checked={item.checked}
																			/>
																		</div>
																	</div>
																</div>
															</label>
															{/*MORE DETAIL BUTTON*/}
															<div className="absolute-bottom-middle center-xs">
																<div
																	className="more-detail-btn"
																	ref={(ref) => {
																		this['btn_show_details_' + i] = ref;
																	}}
																	onClick={() => {
																		window.$(this['custom-segment-' + i]).toggleClass('active');
																	}}
																>
																	<i className="fas fa-chevron-down"/>
																	<i className="fas fa-chevron-up"/>
																</div>
															</div>
														</div>


													</div>
													{/*MORE DETAIL*/}
													{
														(item.status_paid === 'over_due' || !item.status_paid) ?
															<div className="more-detail">
																<div className="flex row center-xs">
																	<div className="col-xs-16">
																		<SaleOrderTabs sale_order_contact={item}
																		               updateState={(item) => {
																			               // ITEM LIST
																			               let sale_order_contacts = [...this.state.sale_order_contacts];
																			               sale_order_contacts[i] = item;
																			               this.setState({
																				               amount: this.summaryAmount(Helper.sumTotalPayment(sale_order_contacts).selected_items),
																				               selected_items: Helper.sumTotalPayment(sale_order_contacts).selected_items,
																				               sale_order_contacts: Helper.sumTotalPayment(sale_order_contacts).sale_order_contacts,
																			               }, () => {
																				               // localStorage.setItem('selected_items', JSON.stringify(this.state.selected_items));
																				               this.updateUrl();
																			               });
																		               }}
																		               key={i}
																		               index={'sale-order-tabs' + i}
																		/>
																	</div>
																</div>
															</div>
															: null
													}
													{
														(
															item.status_paid === "complete"
															|| item.status_paid === "waiting_to_approve_payment_by_bank_transfer_draft"
														)
														&& item.payment_by_bank_transfer_draft
															?
															<div className="more-detail">
																<div className="flex row center-xs">
																	<div className="col-xs-16 col-lg-5">
																		<a className="picture-frame flex center-xs"
																		   href={item.payment_by_bank_transfer_draft.payment_by_bank_transfer_draft_file_upload[0].file_upload.url}
																		   target="_blank"
																		   rel="noopener noreferrer">
																			<img
																				src={item.payment_by_bank_transfer_draft.payment_by_bank_transfer_draft_file_upload[0].file_upload.url}
																				alt="หลักฐานการชำระเงิน"
																			/>
																		</a>
																	</div>
																</div>
															</div>
															: null
													}


												</div>
											)
										})
										:
										null
								}
								{
									this.state.sale_order_contacts.length === 0
									&& !this.state.isLoading ?
										<div className="ui segment custom-segment">ไม่มีข้อมูล</div>
										:
										null
								}
								{
									this.state.isLoading ?
										<div className="ui active centered inline loader"/>
										: null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
