export const Global2 = {
	getMyProfile: () => {
		let data = window.Global.getCookie('relationship_account');
		return data ? JSON.parse(data) : false;
	},
	loginCheck: (url) => {

		if (!Global2.getMyProfile().id) {
			window.Global.messageTop({
				type: 'negative',
				message: "โปรดเข้าสู่ระบบเพื่อเข้าใช้งาน",
			});
			return false;
		}
		window.location.href = url;
	},
	getQuantity: (sale_order_item) => {
		if (sale_order_item.sale_order_item_product_display_link._quantity) {
			return sale_order_item.sale_order_item_product_display_link._quantity.format(2, 3, {pretty: true}).toString()
		}
		return sale_order_item.quantity2;
	}
};

// function objToQueryString(obj) {
// 	const keyValuePairs = [];
// 	for (const key in obj) {
// 		keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
// 	}
// 	return keyValuePairs.join('&');
// }
//
// function objectToFormData(obj, form, namespace) {
// 	// https://gist.github.com/ghinda/8442a57f22099bdb2e34
// 	var fd = form || new FormData();
// 	var formKey;
//
// 	for (var property in obj) {
// 		if (obj.hasOwnProperty(property)) {
//
// 			if (namespace) {
// 				formKey = namespace + '[' + property + ']';
// 			} else {
// 				formKey = property;
// 			}
//
// 			// if the property is an object, but not a File,
// 			// use recursivity.
// 			if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
//
// 				objectToFormData(obj[property], fd, property);
//
// 			} else {
//
// 				// if it's a string or a File object
// 				fd.append(formKey, obj[property]);
// 			}
//
// 		}
// 	}
//
// 	return fd;
// }
//
// let serialize = function (obj, prefix) {
// 	let str = [],
// 		p;
// 	for (p in obj) {
// 		if (obj.hasOwnProperty(p)) {
// 			var k = prefix ? prefix + "[" + p + "]" : p,
// 				v = obj[p];
// 			str.push((v !== null && typeof v === "object") ?
// 				serialize(v, k) :
// 				encodeURIComponent(k) + "=" + encodeURIComponent(v));
// 		}
// 	}
// 	return str.join("&");
// };