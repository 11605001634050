import React from 'react';
import Header from "./Header";

export default class SaleOrderPaymentByBankTransfer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: window.Global.getAllQueryStrings().amount || 0,
			route_id: window.Global.getAllQueryStrings().route_id,
			payment: {},
			total_net_price: window.Global.getAllQueryStrings().amount || 0,
			is_loading: true,
			token: window.Global.getAllQueryStrings().token || '',
		}
	}

	componentDidMount() {
		window.Global.ajax({
			method: 'GET',
			url: window.host + '/api/v1/e-commerce/payment',
			data: {
				payment_id: window.Global.getAllQueryStrings().payment_id,
			},
			done: (response) => {
				this.setState({
					payment: response.payment,
					is_loading: false,
				}, () => {
					this.checkPaymentType();
				})
			}
		});
	}

	componentWillUnmount() {
		window.clearInterval(window.qrcode_payment);
	}

	checkPaymentType() {
		window.qrcode_payment = window.setInterval(() => {
			window.Global.ajax({
				method: 'GET',
				url: window.host + '/api/v1/e-commerce/payment',
				data: {
					payment_id: window.Global.getAllQueryStrings().payment_id,
				},
				done: (response) => {
					if (response.payment.type === 'pay_in_sale_order') {
						// window.Global.messageTop({
						// 	node: window.$('body'),
						// 	type: 'positive',
						// 	message: 'ขอบคุณมาก แจ้งการโอนเงินเรียบร้อยแล้ว โปรดรอการตรวจสอบจากเจ้าหน้าที่'
						// });
						window.location = '/payment-success?payment_by=qrcode&amount=' + this.state.amount + '&token=' + this.state.token;
					}
				}
			});
		}, 3000);
	}

	render() {
		return (
			<div className='full-bg padded wetv_bg flex'>
				<Header/>
				<div className="flex row padded center-xs">
					<div className="col-xs-16 col-lg-4 padded">
						<form className="ui form segments">
							{/*<input type="hidden" name="total_net_price"*/}
							{/*value={window.Global.getAllQueryStrings().amount}/>*/}
							{/*PAYMENT INFO*/}
							<div className="ui segment row middle-xs">
								<strong>ยอดที่ต้องชำระ</strong>
								<div className={['right floated']}>
									<span>฿ </span>
									<span
										className="lg-font font-bold">{this.state.amount.format(2, 3, {pretty: false})}</span>
								</div>
							</div>

							{/*<div className="ui segment inverted custom-title" style={{textAlign: 'center'}}>*/}
							{/*	<span>กรอกรายละเอียดการโอนเงิน</span>*/}
							{/*</div>*/}
							<div
								className={this.state.is_loading ? "ui segment center-xs loading" : "ui segment center-xs"}>
								{
									!this.state.is_loading ?
										<>
											<div style={{textAlign: 'center'}}>
												<img className="item-image"
												     src={this.state.payment.payment[0].payment_by_gbprimepay_qrcode.qrcode_image_url}
												     alt="checked"
												     style={{maxWidth: '100%'}}
												/>
											</div>
											<div style={{textAlign: 'center'}}>
												<button className={"ui button basic"}
												        type={"button"}
												        onClick={() => {
													        window.Global.reactToDom(window.$('#call_iframe'),
														        <iframe style={{border: 0, width: 0, height: 0}}
														                title={"Download QRCode"}
														                src={this.state.payment.payment[0].payment_by_gbprimepay_qrcode.qrcode_image_url + '&output_type=download'}
														        />);
												        }}
												>
													ดาวน์โหลด QRCode
												</button>
											</div>
										</>
										: null
								}
								<div id={"call_iframe"} style={{height: 0, overflow: 'hidden'}}/>
							</div>

							<div className="ui segment row between-xs">
								<a className="ui button oval"
								   ref={(ref) => {
									   this.cancel_btn = ref;
								   }}
								   onClick={() => {
									   window.$(this.cancel_btn).addClass('loading');
									   setTimeout(() => {
										   window.$(this.cancel_btn).removeClass('loading');
									   }, 5000)
								   }}
								   href={'/payment-method?amount=' + this.state.amount + '&route_id=' + this.state.route_id + '&token=' + this.state.token}>
									กลับไปเลือกวิธีชำระเงิน
								</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}