import React from 'react';
import {Modal} from "../component/Modal";

export default class VerifyEmail extends React.Component {
	constructor() {
		super();
		this.state = {
			message_form: null
		}
	}

	render() {
		return (
			<div>
				<Modal className="ui tiny modal"
				       onShowToSetupForm={($modal) => {
					       $modal.find('form').form2('setup');
					       $modal.find('.close.icon').remove();
					       $modal.find('form').form2('submit', {
						       method: 'POST',
						       url: window.host + '/api/v1/e-commerce/relationship/account/request_confirm_email',
						       beforeSent: (object) => {
							       return object;
						       },
						       loadingButton: $modal.find('.button.approve'),
						       onSuccess: (response) => {
							       console.log(response);
							       if (response.success) {
								       this.setState({
									       message_form: response.message
								       })
							       }
						       }
					       });
				       }}
				>
					<div className="header">
						<div className="flex flex-row">
							ยืนยันอีเมล
						</div>
					</div>
					<div className="content">
						{
							this.state.message_form ?
								<div className="ui success icon message">
									<i className="paper plane icon"/>
									<div className="content">
										<div className="header">
											{this.state.message_form}
										</div>
									</div>
								</div>
								: null
						}
						{
							!this.state.message_form ?
								<form className={"ui form"}>
									<div className="field">
										<label>อีเมล</label>
										<input type={"text"} name={"emai"}/>
									</div>
									<div className="field GoogleRecaptcha_root"/>
									<div className="ui error message"/>
								</form>
								: null
						}
					</div>
					<div className="actions">
						<button className="ui deny button oval"
								type={"button"}
								style={{marginLeft: 15}}
						>
							ปิด
						</button>
						{
							!this.state.message_form ?
								<button className="ui approve button wetv-btn oval"
								        type={"button"}
								        style={{marginLeft: 15}}
								>
									ตกลง
								</button>
								: null
						}
					</div>
				</Modal>
			</div>
		);
	}
}