import React from "react";

export default class SaleOrderTabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sale_order_contact: props.sale_order_contact
		};
	}

	onSelected = (sale_order_contact) => {
		// หากมากกว่า 0 จะเช็ค กรอบนอก
		let bill_checked_count = sale_order_contact.sale_order_contact_items.filter((sale_order_contact_item) => {
			return sale_order_contact_item.checked === true
				&& (sale_order_contact_item.status_paid === 'over_due' ||
					sale_order_contact_item.status_paid === 'advance' ||
					(sale_order_contact_item.payment_by_bank_transfer_draft
						&& sale_order_contact_item.payment_by_bank_transfer_draft.status === 'rejected'))
		}).length;
		sale_order_contact.checked = bill_checked_count > 0;
		this.setState({
			sale_order_contact: sale_order_contact
		}, () => {
			this.props.updateState(this.state.sale_order_contact);
		});
	};

	countSaleOrderContactItem(type) {
		let count_sale_order_contact_item_can_pay = 0;
		let count_sale_order_contact_item_complete = 0;
		if (this.state.sale_order_contact.sale_order_contact_items
			&& this.state.sale_order_contact.sale_order_contact_items.length > 0) {
			this.state.sale_order_contact.sale_order_contact_items.forEach((sale_order_contact_item, i) => {
				if (sale_order_contact_item.status_paid === 'over_due'
					|| sale_order_contact_item.status_paid === 'advance'
					|| sale_order_contact_item.status_paid === 'waiting_to_approve_payment_by_bank_transfer_draft') {
					count_sale_order_contact_item_can_pay++;
				}
				if (sale_order_contact_item.status_paid === 'complete') {
					count_sale_order_contact_item_complete++
				}
			});
		}
		if (type === 'complete') {
			return count_sale_order_contact_item_complete;
		}
		return count_sale_order_contact_item_can_pay;
	}

	render() {
		return (
			<>
				{
					!this.props.hide_download_invoice ?
						<div className="row center-xs download-btn">
							{
								this.state.sale_order_contact.last_invoice_download_url ?
									<a className="ui button wetv-btn oval"
									   ref={(ref) => {
										   this['download_payment_btn_' + this.props.index] = ref;
									   }}
									   onClick={() => {
										   window.$(this['download_payment_btn_' + this.props.index]).addClass('loading');
										   setTimeout(() => {
											   window.$(this['download_payment_btn_' + this.props.index]).removeClass('loading');
										   }, 3000)
									   }}
									   href={window.host + this.state.sale_order_contact.last_invoice_download_url}
									   target="_blank"
									   rel="noopener noreferrer">ดาวน์โหลดใบแจ้งหนี้</a>
									:
									<button className="ui button wetv-btn oval disabled-grey"
									        type={"button"}>ดาวน์โหลดใบแจ้งหนี้</button>
							}

						</div>
						: null
				}
				<div className="tabs-title"
				     ref={(ref) => {
					     window.$(ref).find('.item').tab();
				     }}
				>
					{
						!this.props.disabled_payment_details ?
							<div className="item active" data-tab={"payment-" + this.props.index}>
								<div className='tabs-title-btn active'>
									รายการชำระเงิน
									<div className="arrow-btm-pos">
										<div className="tabs-triangle"/>
									</div>
								</div>
							</div>
							: null
					}
					{
						!this.props.disabled_history ?
							<div className="item" data-tab={"history-" + this.props.index}>
								<div className='tabs-title-btn'>
									ประวัติชำระเงิน
									<div className="arrow-btm-pos">
										<div className="tabs-triangle"/>
									</div>
								</div>
							</div>
							: null
					}
				</div>
				<div
					className={this.props.tab_no_border ? "tabs-info ui tab active no-border" : "tabs-info ui tab active"}
					data-tab={"payment-" + this.props.index}>
					<div className="ui relaxed divided payment-list list">
						{
							this.countSaleOrderContactItem() > 0
							&& this.state.sale_order_contact.sale_order_contact_items
							&& this.state.sale_order_contact.sale_order_contact_items.length > 0 ?
								this.state.sale_order_contact.sale_order_contact_items.map((sale_order_contact_item, i) => {
									if (sale_order_contact_item.status_paid === 'over_due'
										|| sale_order_contact_item.status_paid === 'waiting_to_approve_payment_by_bank_transfer_draft'
										|| sale_order_contact_item.status_paid === 'advance') {
										return (
											<label key={i}
											       className={this.props.custom_item_style ? "item " + this.props.custom_item_style : "item"}
											       htmlFor={"checkbox_item_" + this.props.index + '_bill_' + +i}
											>
												<div className="row wrap flex middle-xs">
													<div>
														<div className="font-bold">{sale_order_contact_item.title}</div>
														{
															!this.props.hide_status
															&& sale_order_contact_item.status_paid_text ?
																<div className={(() => {
																	if (sale_order_contact_item.status_paid === 'over_due'
																		|| (sale_order_contact_item.payment_by_bank_transfer_draft && sale_order_contact_item.payment_by_bank_transfer_draft.status === 'rejected')) {
																		return "status-btn sm danger"
																	} else {
																		return "status-btn sm"
																	}
																})()} style={{marginTop: 5}}>
																	{sale_order_contact_item.status_paid_text}
																</div>
																: null
														}
													</div>
													<div className="right floated flex row block-after middle-xs">
														<div className="segment-price"
														     style={this.props.hide_checkbox ? {marginRight: 0} : {}}>
															<span className="sub-title">฿ </span>
															<span className="title">
															{(sale_order_contact_item.total_net_price).format(2, 3, {pretty: false}).toString().slice(0, -3)}
														</span>
															<span
																className="sub-title">{(sale_order_contact_item.total_net_price).fraction(2, 3, {pretty: false})}</span>
														</div>
														{
															!this.props.hide_checkbox ?
																<div className="ui checkbox"
																     ref={(ref) => {
																	     window.$(ref).checkbox();
																     }}
																>
																	<input type="checkbox"
																	       id={"checkbox_item_" + this.props.index + '_bill_' + +i}
																	       onChange={(e) => {
																		       let checked = e.target.checked;
																		       let sale_order_contact = {...this.state.sale_order_contact};
																		       if (checked === true) {
																			       this.state.sale_order_contact.sale_order_contact_items.forEach((bill, bill_i) => {
																				       if (bill.status_paid === 'over_due'
																					       || bill.status_paid === 'advance'
																					       || (bill.status_paid === 'waiting_to_approve_payment_by_bank_transfer_draft'
																						       && bill.payment_by_bank_transfer_draft && bill.payment_by_bank_transfer_draft.status === 'rejected')) {
																					       if (sale_order_contact_item.id >= bill.id) {
																						       sale_order_contact.sale_order_contact_items[bill_i].checked = true;
																					       }
																					       if (sale_order_contact_item.id < bill.id) {
																						       sale_order_contact.sale_order_contact_items[bill_i].checked = false;
																					       }
																				       }
																			       });
																		       }
																		       if (checked === false) {
																			       this.state.sale_order_contact.sale_order_contact_items.forEach((bill, bill_i) => {
																				       if (bill.status_paid === 'over_due'
																					       || bill.status_paid === 'advance'
																					       || bill.status_paid === 'waiting_to_approve_payment_by_bank_transfer_draft') {
																					       if (sale_order_contact_item.id <= bill.id) {
																						       sale_order_contact.sale_order_contact_items[bill_i].checked = false
																					       }
																				       }
																			       });
																		       }
																		       this.onSelected(sale_order_contact);
																	       }}
																	       disabled={
																		       this.props.disabled_checkbox ? true :
																			       !!(sale_order_contact_item.status_paid === "waiting_to_approve_payment_by_bank_transfer_draft"
																				       && (sale_order_contact_item.payment_by_bank_transfer_draft
																					       && sale_order_contact_item.payment_by_bank_transfer_draft.status !== 'rejected'))
																	       }
																	       checked={sale_order_contact_item.status_paid === "waiting_to_approve_payment_by_bank_transfer_draft" && (sale_order_contact_item.payment_by_bank_transfer_draft && sale_order_contact_item.payment_by_bank_transfer_draft.status !== 'rejected') ? false : sale_order_contact_item.checked}
																	/>
																</div>
																: null
														}

													</div>
												</div>
											</label>
										);
									}
									return null;
								})
								:
								<div className="item" style={{paddingTop: 15, paddingBottom: 15}}>
									<div className="row center-xs">
										<strong>ไม่พบข้อมูล</strong>
									</div>
								</div>
						}
					</div>
				</div>
				<div className="tabs-info ui tab no-padded" data-tab={"history-" + this.props.index}>
					{
						this.countSaleOrderContactItem('complete') > 0 ?
							<>
								<div className="title-history gray-bg" style={{textAlign: 'center'}}>
									<div className="row wrap flex">
										<div><span>วันที่ชำระเงิน</span></div>
										<div className="right floated flex row block-after">
											<span>ยอดชำระ</span>
										</div>
									</div>
								</div>
								<div className="ui relaxed payment-list divided list">
									{
										this.state.sale_order_contact.sale_order_contact_items
										&& this.state.sale_order_contact.sale_order_contact_items.length > 0 ?
											this.state.sale_order_contact.sale_order_contact_items.map((sale_order_contact_item, i) => {
													if (sale_order_contact_item.status_paid === 'complete') {
														return (
															<div key={i} className="item">
																<div className="row wrap flex">
																	<div className="flex center-xs">
																		<div className="font-bold">
																			{sale_order_contact_item.title}
																			{/*<br/>*/}
																			{/*{*/}
																			{/*	sale_order_contact_item.download_pdf_url ?*/}
																			{/*		<a className="ui status-btn sm button"*/}
																			{/*		   style={{marginRight: 5}}*/}
																			{/*		   href={window.host + sale_order_contact_item.download_pdf_url}*/}
																			{/*		   target="_blank"*/}
																			{/*		   rel="noopener noreferrer"*/}
																			{/*		   ref={(ref) => {*/}
																			{/*			   this['button_pdf' + i] = ref;*/}
																			{/*		   }}*/}
																			{/*		   onClick={() => {*/}
																			{/*			   window.$(this['button_pdf' + i]).addClass('loading');*/}
																			{/*			   setTimeout(() => {*/}
																			{/*				   window.$(this['button_pdf' + i]).removeClass('loading');*/}
																			{/*			   }, 3000)*/}
																			{/*		   }}*/}
																			{/*		>*/}
																			{/*			ดาวน์โหลดใบเสร็จรับเงิน*/}
																			{/*		</a>*/}
																			{/*		:*/}
																			{/*		null*/}
																			{/*}*/}
																		</div>
																	</div>
																	{
																		sale_order_contact_item.total_net_price ?
																			<div
																				className="right floated flex row center-xs">
																				<div className="segment-price"
																				     style={{marginRight: 0}}>
																					<span className="sub-title">฿ </span>
																					<span
																						className="title">{sale_order_contact_item.total_net_price ? (sale_order_contact_item.total_net_price).format(0, 3, {pretty: false}) : (0).format(0, 3, {pretty: false})}</span>
																					<span
																						className="sub-title">{sale_order_contact_item.total_net_price ? (sale_order_contact_item.total_net_price).fraction(2, 3, {pretty: false}) : (0).fraction(2, 3, {pretty: false})} </span>

																				</div>
																			</div>
																			: null
																	}
																</div>
															</div>
														);
													}
													return null
												}
											)
											: null
									}
								</div>
							</>
							:
							<div className="ui relaxed payment-list divided list"
							     style={{paddingTop: 15, paddingBottom: 15}}>
								<div className="item">
									<div className="row center-xs">
										<strong>ไม่พบประวัติชำระเงิน</strong>
									</div>
								</div>
							</div>
					}


				</div>
			</>
		);
	}
}
