import React from 'react';
import Header from "./Header";
import SaleOrderTabs from './SaleOrderTabs';
import Helper from './Helper';
// import SaleOrderPaymentByCreditCard from "./SaleOrderPaymentByCreditCard";

export default class SaleOrderPaymentMethod extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: window.Global.getAllQueryStrings().amount,
			route_id: window.Global.getAllQueryStrings().route_id,
			token: window.Global.getAllQueryStrings().token || '',
			sale_order_contacts: [],
			selected_sale_order_contacts: [],
			isLoading: true,
			payment_method: [
				{text: 'QR Code (Mobile Banking)', value: 'qrcode', iconClass: "qrcode icon"},
				{text: 'บัตรเครดิต (ค่าธรรมเนียม 3.2%)', value: 'credit-card', iconClass: "credit card outline icon"},
				{text: 'โอนเงิน', value: 'bank-transfer', iconClass: "mobile alternate icon"},
			],
			is_payment_by_credit_card: false,
			// isBankLoading: true,
		}
	}

	componentDidMount() {
		window.Global.createCookie('payment_withholding_tax', '', 1)
		window.Global.ajax({
			method: 'GET',
			url: window.host + '/api/v1/e-commerce/relationship/payment-summary',
			data: {
				// token: this.state.token,
				// password: localStorage.getItem('password'),
			},
			done: (response) => {
				if (response.error) {
					window.location = '/?token=' + this.state.token;
				}
				if (!response.success) {
					return false;
				}
				// console.log(response.sale_order_contacts);
				let sale_order_contacts = Helper.setSelectedItem(response.sale_order_contacts, this.state.route_id);
				this.setState({
					relationship: response.relationship_account.relationship,
					selected_items: Helper.sumTotalPayment(sale_order_contacts).selected_items,
					sale_order_contacts: Helper.sumTotalPayment(sale_order_contacts).sale_order_contacts,
					selected_sale_order_contacts: Helper.sumTotalPayment(sale_order_contacts).selected_sale_order_contacts,
					isLoading: false,
				}, () => {
					this.$form.trigger('submit');
				});
			}
		});
	};

	callCreditCardPayment(options, amount) {
		this.setState({
			is_payment_by_credit_card: true,
			payment_withholding_tax: options ? options : false,
			amount: amount,
		}, () => {
			this.$pay_btn.removeClass('loading');
		});
	}

	render() {
		return (
			<div className='full-bg padded wetv_bg flex'>
				<Header/>
				<div className="flex row padded center-xs">
					<div className="col-xs-16 col-lg-6 padded" id={"paymentMethodRoot"}>
						{
							!this.state.is_payment_by_credit_card ?
								<form className="ui form segments"
								      ref={(ref) => {
									      this.$form = window.$(ref);
									      let amount;
									      window.$(ref).form2('setup');
									      window.$(ref).form2('submit', {
										      method: 'POST',
										      url: window.host + '/api/v1/e-commerce/payment',
										      beforeSent: (data) => {
											      this.$form.addClass('loading');
											      let _data = {}
											      if (data.payment_method === 'qrcode') {
												      _data = Helper.preparePaymentData(this.state.sale_order_contacts, {
													      amount: data.amount,
													      payment_by: 'gbprimepay',
													      type: 'qrcode'
												      });
											      }
											      if (data.payment_method === 'credit-card') {
												      _data = Helper.preparePaymentData(this.state.sale_order_contacts, {
													      amount: data.amount,
													      payment_by: 'gbprimepay',
													      type: 'credit_card'
												      });
											      }
											      if (data.payment_method === 'bank-transfer') {
												      _data = Helper.preparePaymentData(this.state.sale_order_contacts, {
													      amount: data.amount,
													      payment_by: 'gbprimepay',
													      type: ''
												      });
											      }
											      amount = data.amount;
											      data = {...data, ..._data};
											      delete data.payment_method;
											      delete data.route_id;
											      delete data.token;
											      if (!data.is_create_payment_withholding_tax) {
												      data.is_create_payment_withholding_tax = 0;
											      }
											      this.$form.find('.ui.error.message').html();
											      this.$form.removeClass('error');
											      return data;
										      },
										      loadingButton: window.$(ref).find('#submit_payment'),
										      onSuccess: (response) => {
											      if (response.success && response.redirect_form) {
												      let input_data = [];
												      for (let _data in response.redirect_form.data) {
													      if (response.redirect_form.data.hasOwnProperty(_data)) {
														      input_data.push({
															      name: _data,
															      value: response.redirect_form.data[_data]
														      });
													      }
												      }
												      this.setState({
													      response: response,
													      input_data: input_data,
												      }, () => {
													      this.submitForm.submit();
												      })
											      }
											      if (response.success
												      && !response.redirect_form
												      && response.payment.payment
												      && response.payment.payment[0].payment_by_gbprimepay_qrcode.qrcode_image_url
											      ) {
												      window.location.href = window.location.origin + window.Global.getQueryStringLinkFromObject({
													      amount: amount,
													      route_id: window.Global.getAllQueryStrings().route_id,
													      token: window.Global.getAllQueryStrings().token,
													      payment_id: response.payment.id
												      }, "/qr-code");
											      }
											      if (response.success
												      && response.payment
												      && response.payment.summary) {
												      this.$total_net_price.text(response.payment.summary.total_net_price.format(2, 3, {pretty: false}))
												      this.$total_price.text(response.payment.summary.total_price.format(2, 3, {pretty: false}))
												      this.$payment_withholding_tax_price.text(response.payment.summary.total_price_withholding_tax.format(2, 3, {pretty: false}))
												      this.$amount_input.val(response.payment.summary.total_net_price);
											      }
											      let credit_card_vat = 0;
											      let credit_card_text = '';
											      if (response.payment.payment_item.length > 0) {
												      response.payment.payment_item.map((payment_item, payment_item_i) => {
													      if (payment_item.product_id === -1) {
														      credit_card_vat = payment_item.summary.total_net_price;
														      credit_card_text = payment_item.product.name;
													      }
													      return false;
												      })
											      }
											      if (credit_card_vat > 0) {
												      window.$('#creditcard_tax_price').show();
											      }
											      if(credit_card_vat === 0 ) {
												      window.$('#creditcard_tax_price').hide();
											      }
											      this.$creditcard_tax_text.text(credit_card_text);
											      this.$creditcard_tax_price.text(credit_card_vat.format(2, 3, {pretty: false}))
											      this.$form.removeClass('loading');
										      },
										      onError: () => {
											      this.$form.removeClass('loading');
										      }

									      });
								      }}
								>
									<input type="hidden" name={"amount"} value={this.state.amount} ref={(ref) => {
										this.$amount_input = window.$(ref)
									}}/>
									<input type="hidden" name={"route_id"} defaultValue={this.state.route_id}/>
									<input type="hidden" name={"token"} defaultValue={this.state.token}/>
									<input type="hidden" name={"is_commit"} defaultValue={0} ref={(ref) => {
										this.$isCommit = window.$(ref);
									}}/>

									<div className="ui segment">
										<div className="field GoogleRecaptcha_root" style={{margin: 0}}/>
										<div className="ui error message"/>
										<div className={"row between-xs"}>
											<a className="ui button oval"
											   ref={(ref) => {
												   this.cancel_btn = ref;
											   }}
											   onClick={() => {
												   window.$(this.cancel_btn).addClass('loading');
												   setTimeout(() => {
													   window.$(this.cancel_btn).removeClass('loading');
												   }, 5000)
											   }}
											   href={'/payment?token=' + this.state.token}>ยกเลิก</a>
											<button className="wetv-btn ui button oval"
											        ref={(ref) => {
												        this.$pay_btn = window.$(ref);
											        }}
											        id={"submit_payment"}
											        type="button"
											        onClick={() => {
												        if (this.$pay_btn.hasClass('loading') ||
													        this.$form.hasClass('loading')) {
													        return;
												        }
												        let data = this.$form.serializeObject();
												        this.$isCommit.val(1);
												        window.Global.createCookie('payment_withholding_tax', JSON.stringify({
													        is_create_payment_withholding_tax: data.is_create_payment_withholding_tax ? 1 : 0,
													        payment_withholding_tax: data.payment_withholding_tax || 0
												        }), 1);
												        if (data.payment_method === 'bank-transfer') {
													        this.$pay_btn.addClass('loading');
													        window.location.href = window.location.origin + window.Global.getQueryStringLinkFromObject({
														        amount: data.amount,
														        route_id: data.route_id,
														        token: data.token,
													        }, "/bill-payment");
													        return;
												        }
												        if (data.payment_method === 'qrcode') {
													        this.$form.trigger('submit');
													        return;
												        }
												        if (data.payment_method === 'credit-card') {
													        this.$pay_btn.addClass('loading');
													        window.location.href = window.location.origin + window.Global.getQueryStringLinkFromObject({
														        amount: data.amount,
														        route_id: data.route_id,
														        token: data.token,
													        }, "/credit-card-payment");
													        // let amount = 1;
													        // let options = {};
													        // options.is_create_payment_withholding_tax = data.is_create_payment_withholding_tax ? 1 : 0;
													        // options.payment_withholding_tax = data.payment_withholding_tax;
													        // this.callCreditCardPayment({...options}, data.amount);
													        return false;
												        }
											        }}
											>
												ตกลง
											</button>
											<button type={"submit"} style={{display: 'none'}}>ยืนยัน</button>
										</div>
									</div>
									{/*BANK TITLE*/}
									<div className="ui segment inverted custom-title" style={{textAlign: 'center'}}>
										<span>เลือกวิธีการชำระเงิน</span>
									</div>
									{
										this.state.isBankLoading ?
											<div className="ui segment">
												<div className="ui active centered inline loader"/>
											</div>
											:
											<>
												{
													this.state.payment_method.map((payment_method, payment_method_i) => {
														return (
															<div key={payment_method_i}
															     className="ui segment custom-segment warning bank-list"
															     ref={(ref) => {
																     window.$(ref).on('click', () => {
																	     this['radio_checkbox' + payment_method_i].checkbox("check");
																     })
															     }}
															     style={{cursor: 'pointer'}}
															>
																<div className="row middle-xs">
																	<div className={"flex middle-xs"}
																	     style={{flexShrink: 0, paddingRight: '0.9em'}}>
																		<div className="ui radio checkbox"
																		     ref={(ref) => {
																			     this['radio_checkbox' + payment_method_i] = window.$(ref);
																			     window.$(ref).checkbox({
																				     onChecked: () => {
																					     this.$form.trigger('submit');
																				     }
																			     })
																		     }}>
																			<input type="radio" name="payment_method"
																			       defaultChecked={payment_method_i === 0}
																			       value={payment_method.value}/>
																		</div>
																	</div>
																	<div className={"middle-xs flex row"}>
																		{payment_method.text}
																	</div>
																	{
																		payment_method.iconClass ?
																			<div className={"right floated"}>
																				<i className={payment_method.iconClass}
																				   style={{fontSize: 24}}/>
																			</div>
																			: null
																	}
																</div>
															</div>
														);
													})
												}
											</>
									}

									<div className="ui segment inverted custom-title" style={{textAlign: 'center'}}>
										<span>สรุปรายการชำระเงิน</span>
									</div>
									<div className={"ui segment cursor-default"}>
										{
											this.state.selected_sale_order_contacts.length > 0 ?
												this.state.selected_sale_order_contacts.map((item, i) => {
													return (
														<div className="custom-segment-space active"
														     key={i}
														     ref={(ref) => {
															     this['custom-segment-' + i] = ref
														     }}

														>
															<div
																className={(() => {
																	let callClass = "ui segment custom-segment no-padded no-margin";
																	// callClass = callClass + " success";
																	callClass = callClass + " normal";
																	return callClass;
																})()}

															>
																<div className="row"
																     style={{position: 'relative'}}>
																	{/*ICON*/}
																	<div className="segment-icon">
																		<div
																			className="segment-icon-bg  center-xs middle-xs flex">
																			<i className={(() => {
																				let callClass = "";
																				callClass = callClass + "fas fa-shopping-bag";
																				// callClass = callClass + "fas fa-file-invoice-dollar";
																				return callClass;
																			})()}/>
																		</div>
																	</div>
																	{/*DETAIL*/}
																	<label className="segment-details"
																	       htmlFor={"checkbox" + i}>
																		<div className="row wrap flex">
																			<div className="flex">
																				<div
																					className="font-bold">{item.title}</div>
																				<div
																					className="status">{item.status_paid_text}</div>
																			</div>
																			<div
																				className="right floated flex row block-after">
																				<div className="segment-price">
																					<span
																						className="sub-title">฿ </span>
																					<span className="title">
																			{item.total_net_price ? item.total_net_price.format(2, 3, {pretty: false}).toString().slice(0, -3) : null}
																		</span>
																					<span
																						className="sub-title">{item.total_net_price ? item.total_net_price.fraction(2, 3, {pretty: false}) : null}</span>
																				</div>
																			</div>
																		</div>
																	</label>
																</div>


															</div>
															{/*MORE DETAIL*/}
															{
																(item.status_paid === 'over_due' || !item.status_paid) ?
																	<div className="more-detail">
																		<div className="flex row center-xs">
																			<div className="col-xs-16">
																				<SaleOrderTabs sale_order_contact={item}
																				               hide_download_invoice={true}
																				               disabled_checkbox={true}
																				               hide_checkbox={true}
																				               hide_status={true}
																				               disabled_history={true}
																				               disabled_payment_details={true}
																				               tab_no_border={true}
																				               custom_item_style={'custom-payment-method-item'}
																				               key={i}
																				               index={'sale-order-tabs' + i}
																				/>
																			</div>
																		</div>
																	</div>
																	: null
															}


														</div>
													)
												})
												:
												null
										}
										{
											this.state.sale_order_contacts.length === 0
											&& !this.state.isLoading ?
												<div className="ui segment custom-segment">ไม่มีข้อมูล</div>
												:
												null
										}
										{
											this.state.isLoading ?
												<div className="ui active centered inline loader"/>
												: null
										}
									</div>
									{/*HOLDINGTAX*/}
									{
										this.state.relationship
										&& this.state.relationship.is_company
										&& this.state.selected_sale_order_contacts.length === 1 ?
											<div className="ui segment ">
												<div className={"flex row middle-xs"}>
													<div className="ui checkbox"
													     ref={(ref) => {
														     this.$checkbox = window.$(ref);
														     this.$checkbox.checkbox({
															     onChecked: () => {
																     this.$payment_withholding_tax.show();
																     this.$form.trigger('submit');
																     window.$('#total_price').show();
																     window.$('#payment_withholding_tax_price').show();
															     },
															     onUnchecked: () => {
																     this.$payment_withholding_tax.hide();
																     this.$form.trigger('submit');
																     window.$('#total_price').hide();
																     window.$('#payment_withholding_tax_price').hide();
															     }
														     });
													     }}
													>
														<input type="checkbox"
														       id={"checkbox"}
														       name={"is_create_payment_withholding_tax"}
														       value={true}
														/>
														<label htmlFor={"checkbox"}>หัก ณ ที่จ่าย</label>
													</div>
												</div>
												<div className={"flex row wrap padded"}
												     ref={(ref) => this.$payment_withholding_tax = window.$(ref)}
												     style={{display: 'none', marginTop: '1rem'}}
												>
													<div className={"col-xs-16 col-lg-8 padded field"}>
														<label>ชื่อผู้มีหน้าที่หัก</label>
														<input type={"text"} name={"payment_withholding_tax[][a_name]"}
														       defaultValue={this.state.relationship.name}/>
													</div>
													<div className={"col-xs-16 col-lg-8 padded field"}>
														<label>เลขประจำตัวผู้เสียภาษีอากร</label>
														<input type={"text"}
														       name={"payment_withholding_tax[][a_tax_number]"}
														       defaultValue={this.state.relationship.tax_number}/>
													</div>
													<div className={"col-xs-16  padded field"}>
														<label>ที่อยุ่</label>
														<input type={"text"}
														       name={"payment_withholding_tax[][a_address]"}
														       defaultValue={this.state.relationship.print_address}/>
													</div>
													<div className={"col-xs-16 col-lg-8 padded field"}>
														<label>เลขที่หนังสือรับรองฯ</label>
														<input type={"text"}
														       name={"payment_withholding_tax[][doc_no]"}/>
													</div>
													<div className={"col-xs-16 col-lg-8 padded field"}>
														<label>วัน เดือน ปี ที่ออกหนังสือรับรองฯ</label>
														<div data-form_calendar="date"
														     data-input_name="payment_withholding_tax[][doc_date]"
														     data-placeholder="วัน เดือน ปี ที่ออกหนังสือรับรองฯ"
														     data-locale="th"
														     data-initialdate="now"
														/>
													</div>
												</div>
											</div>

											: null
									}
									{/*PAYMENT INFO*/}
									<div className="ui segment row middle-xs" id={"creditcard_tax_price"}
									     style={{display: 'none'}}>
										<strong ref={(ref) => {
											this.$creditcard_tax_text = window.$(ref)
										}}>
											{/*ค่าธรรมเนียม*/}
										</strong>
										<div className={['right floated']}>
											<span>฿ </span>
											<span ref={(ref) => {
												this.$creditcard_tax_price = window.$(ref)
											}}
											      className="lg-font font-bold">
												{(0).format(2, 3, {pretty: false})}
											</span>
										</div>
									</div>
									<div className="ui segment row middle-xs" id={"total_price"}
									     style={{display: 'none'}}>
										<strong>จำนวนเงินรวมทั้งสิ้น</strong>
										<div className={['right floated']}>
											<span>฿ </span>
											<span ref={(ref) => {
												this.$total_price = window.$(ref)
											}}
											      className="lg-font font-bold">
												{(0).format(2, 3, {pretty: false})}
											</span>
										</div>
									</div>
									<div className="ui segment row middle-xs" id={"payment_withholding_tax_price"}
									     style={{display: 'none'}}>
										<strong>หักภาษี ณ ที่จ่าย
										</strong>
										<div className={['right floated']}>
											<span>฿ </span>
											<span ref={(ref) => {
												this.$payment_withholding_tax_price = window.$(ref)
											}}
											      className="lg-font font-bold">
												{(0).format(2, 3, {pretty: false})}
											</span>
										</div>
									</div>
									<div className="ui segment row middle-xs">
										<strong>ยอดที่ต้องชำระ</strong>
										<div className={['right floated']}>
											<span>฿ </span>
											<span ref={(ref) => {
												this.$total_net_price = window.$(ref)
											}}
											      className="lg-font font-bold">
												{/*{this.state.amount.format(2, 3, {pretty: false})}*/}
											</span>
										</div>
									</div>
								</form>
								: null
						}
						{
							this.state.response ?
								<form action={this.state.response.redirect_form.url} method={"POST"}
								      ref={(ref) => {
									      this.submitForm = window.$(ref);
								      }}
								>
									{
										this.state.input_data ?
											this.state.input_data.map((input_data) => {
												return (
													<input name={input_data.name} value={input_data.value}/>
												);
											})
											: null
									}
								</form>
								: null
						}
					</div>
				</div>
			</div>
		);
	}
}