import React from 'react';
import './header.css';
import {Link} from 'react-router-dom';
import {Global2} from './component/Global'

export default class Header extends React.Component {
	render() {
		return (
			<div className="ui fixed menu">
				<div className="sign-out">
					{
						localStorage.getItem('token') ?
							<div
								ref={(ref) => {
									this.sign_out = ref;
								}}
								onClick={() => {
									window.$(this.sign_out).html("<div class=\"ui active centered small inline loader \"></div>");
									// let qs = window.Global.getAllQueryStrings();
									localStorage.removeItem('password');
									localStorage.removeItem('token');
									window.Global.createCookie('relationship_account', '', 365);
									window.location = '/';
								}}
							>
								<i className="fas fa-sign-out-alt"/>
							</div>
							: null
					}

				</div>
				<div className="ui container center-xs">
					<Link
						to={Global2.getMyProfile().id && window.location.pathname !== '/register' ? "/payment?token=" + Global2.getMyProfile().token : '/'}>
						<img className="header_logo" src="assets/images/logo.png" title="logo" alt="logo"/>
					</Link>
				</div>
			</div>
		);
	}
}