const Helper = {
	preparePaymentData: (sale_order_contacts, payment_by) => {
		let data = {};
		data.items = [];
		data.payment_by = [];
		data.payment_by.push(payment_by);

		sale_order_contacts.forEach((_item) => {
			if (!_item.sale_order_contact_items) {
				data.items.push({
					sale_order_item_id: _item.sale_order_item_id,
					_quantity: parseFloat(_item.quantity) / parseFloat(_item.ratio) || 1,
				})
			}
			if (_item.sale_order_contact_items) {
				_item.sale_order_contact_items.forEach((_sale_order_contact_items) => {
					data.items.push({
						sale_order_item_id: _sale_order_contact_items.data.sale_order_item_id,
						_quantity: parseFloat(_item.quantity) / parseFloat(_item.ratio) || 1,
						payment_item_monthly: {
							billing_cycle_end_date: _sale_order_contact_items.data.billing_cycle_end_date,
							billing_cycle_start_date: _sale_order_contact_items.data.billing_cycle_start_date,
						},
					})
				})
			}
		});
		return data;
	},
	sumTotalPayment: (sale_order_contact) => {
		let state_items = [...sale_order_contact];
		// คำนวนราคาแต่ละรายการ
		state_items.forEach((state_item, state_item_index) => {
			if (state_item.sale_order_contact_items
				&& state_item.sale_order_contact_items.length > 0) {
				let total_net_price = 0;
				state_item.sale_order_contact_items.forEach((sale_order_contact_item) => {
					if (sale_order_contact_item.checked === true
						&& (sale_order_contact_item.status_paid === 'over_due'
							|| sale_order_contact_item.status_paid === 'advance'
							|| (sale_order_contact_item.payment_by_bank_transfer_draft
								&& sale_order_contact_item.payment_by_bank_transfer_draft.status === 'rejected'))
					) {
						total_net_price += parseFloat(sale_order_contact_item.total_net_price);
					}
				});
				state_items[state_item_index].total_net_price = total_net_price;
			}
		});
		let selected_items = [];
		let selected_sale_order_contacts = [];
		state_items.forEach((item) => {
			let count_selected_item = 0;
			item.sale_order_contact_items.forEach((sale_order_contact_item) => {
				if (sale_order_contact_item.checked === true
					&& (sale_order_contact_item.status_paid === 'over_due'
						|| sale_order_contact_item.status_paid === 'advance'
						|| (sale_order_contact_item.payment_by_bank_transfer_draft
							&& sale_order_contact_item.payment_by_bank_transfer_draft.status === 'rejected'))
				) {
					selected_items.push(sale_order_contact_item);
					count_selected_item++;
				}
			});
			if (count_selected_item > 0) {
				selected_sale_order_contacts.push(item);
			}
		});
		let data = {
			selected_items: selected_items,
			sale_order_contacts: state_items,
			selected_sale_order_contacts: selected_sale_order_contacts,
		};
		return data;
	},
	setSelectedItem: (sale_order_contacts, _route_id) => {

		let _sale_order_contacts = [...sale_order_contacts];
		sale_order_contacts.forEach((sale_order_contact, sale_order_contacts_i) => {
			let route_id = _route_id.split(",");
			let _sale_order_contact_items = [];
			_sale_order_contacts[sale_order_contacts_i].checked = true;
			let total_net_price = 0;

			sale_order_contact.sale_order_contact_items.forEach((sale_order_contact_item, sale_order_contact_items_i) => {
				route_id.forEach((_route_id) => {
					if (parseFloat(_route_id) === parseFloat(sale_order_contact_item.id)) {
						let _sale_order_item = {...sale_order_contact_item};
						_sale_order_item.checked = true;
						if (_sale_order_item.checked === true
							&& _sale_order_item.status_paid === "over_due") {
							total_net_price = total_net_price + parseFloat(_sale_order_item.amount);
						}
						_sale_order_contact_items.push(_sale_order_item);
					}
				})
			});
			_sale_order_contacts[sale_order_contacts_i].sale_order_contact_items = _sale_order_contact_items;
		});

		return _sale_order_contacts;
	}
};
export default Helper;