import React from 'react';
import Header from "./Header";

export default class RegisterFirstTime extends React.Component {
    constructor() {
        super();
        this.state = {
            is_register_success: false,
        }
    }


    componentDidMount() {
        let qs = window.Global.getAllQueryStrings();
        this.$parent.find('form').form2('submit', {
            method: 'PUT',
            url: window.host + '/api/v1/e-commerce/relationship_account/first',
            beforeSent: (data) => {
                return data;
            },
            loadingButton: this.$parent.find('.button'),
            onSuccess: (response) => {
                // console.log(response);
                let message = {};
                message.message = response.message;
                window.Global.createCookie('register_success_messages', JSON.stringify(message), 365);
                // window.Global.messageTop({
                //     node: window.$('body'),
                //     type: 'positive',
                //     message: 'ลงทะเบียนสำเร็จ'
                // });
                window.location = '/payment?token=' + qs.token;
            }
        });
    }

    render() {
        return (
            <div ref={(ref) => {
                this.$parent = window.$(ref);
            }} className="wetv_bg">
                <Header/>
                <div className="flex row padded center-xs">
                    <div className="col-xs-16 col-lg-6 padded">
                        <div className="ui segments card-center">
                            <div className="ui segment secondary center-xs">
                                ลงทะเบียนครั้งแรก
                            </div>
                            <div className="ui segment">
                                <form className="ui form start-xs">

                                    <div className="field required">
                                        <label>ตั้งรหัสผ่านใหม่</label>
                                        <input type="password"
                                               name="password"
                                        />
                                    </div>
                                    <div className="field required">
                                        <label>ยืนยันรหัสผ่าน</label>
                                        <input type="password"
                                               name="confirm_password"
                                        />
                                    </div>
                                    <div className="field required">
                                        <label>เบอร์โทรศัพท์มือถือ</label>
                                        <input type="text"
                                               name="phone_number"
                                        />
                                    </div>
                                    <div className="field">
                                        <label>อีเมล</label>
                                        <input type="email"
                                               name="email"
                                        />
                                    </div>
                                    <div className="field GoogleRecaptcha_root" style={{margin: 0}}/>
                                    <div className="ui error message"/>
                                    <button type="submit" className="ui button wetv-btn oval">ยืนยัน</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}