import React from 'react';
import Header from "./Header";

export default class ForgotPassword extends React.Component {

	constructor() {
		super();
		localStorage.removeItem('password');
		window.Global.createCookie('relationship_account', '', 365);
		this.state = {
			show_message: null
		}
	}

	componentDidMount() {

	}

	render() {
		return (
			<div ref={(ref) => {
				this.$parent = window.$(ref);
			}} className="wetv_bg">
				<Header/>
				<div className="flex row center-xs padded">
					<div className="col-xs-16 col-lg-4 padded">
						{
							this.state.show_message ?
								<div className="ui icon message green">
									<i className="paper plane icon"/>
									<div className="content">
										<div className="header">
											{this.state.show_message}
										</div>
									</div>
								</div>
								:
								<div className="ui segments card-center">
									<div className="ui segment secondary center-xs">
										ลืมรหัสผ่าน
									</div>
									<div className="ui segment">
										<div className="center-xs" style={{marginBottom: 15}}>
											ระบุชื่อผู้ใช้งานหรืออีเมล์เพื่อตั้งค่ารหัสผ่านใหม่
										</div>
										<form
											className="ui form start-xs"
											ref={(ref) => {
												this.form = ref;
												window.$(this.form).form2('setup');
												window.$(this.form).form2('submit', {
													url: window.host + '/api/v1/e-commerce/request-forgot-password',
													beforeSent: (data) => {
														return data;
													},
													loadingButton: window.$(this.form).find('button[type="submit"]'),
													onSuccess: (response) => {
														this.setState({show_message: response.message});
													}
												});

											}}
										>
											<div className="field">
												<label>ชื่อผู้ใช้งาน / อีเมล</label>
												<input type="text"
												       name="username"
													// defaultValue={this.state.username}
												/>
											</div>

											<div className="field GoogleRecaptcha_root" style={{margin: 0}}/>
											<div className="ui error message"/>
											<div className={"flex row"}>
												<button type="submit" className="ui button wetv-btn oval">ยืนยัน
												</button>
											</div>
										</form>
									</div>
								</div>
						}

					</div>
				</div>
			</div>
		);
	}
}