import React from 'react';
import Header from "./Header";
import {Link} from 'react-router-dom'

export default class LoginWithUsernameAndPassword extends React.Component {

	constructor() {
		super();
		this.state = {
			password: "1234",
			username: '',
		};
		localStorage.removeItem('password');
		// window.Global.createCookie('relationship_account', '', 365);
	}

	componentDidMount() {
		// let qs = window.Global.getAllQueryStrings();
		if(localStorage.getItem('token')
				&& localStorage.getItem('token')!== ""
				&& window.Global.getCookie('relationship_account')
				&& window.Global.getCookie('relationship_account')!== ""
		) {
			let token = localStorage.getItem('token');
			let relationship_account = JSON.parse(window.Global.getCookie('relationship_account'));
			if(!relationship_account.is_set_password) {
				window.location = '/register?token=' + token;
			}
			if(relationship_account.is_set_password) {
				window.location = '/payment?token=' + token;
			}
		}
		this.$parent.find('form').form2('submit', {
			method: 'POST',
			url: window.host + '/api/v1/relationship/account/login',
			// url: window.host + '/api/v1/e-commerce/relationship/payment-summary',
			beforeSent: (data) => {
				return data;
			},
			loadingButton: this.$parent.find('.button'),
			onSuccess: (response) => {
				// console.log(response);
				if (response.success) {
					let relationship_account_set_cookie = {};
					relationship_account_set_cookie = response.relationship_account;
					relationship_account_set_cookie.relationship = {};
					relationship_account_set_cookie.relationship.name = response.relationship_account.relationship.name;
					window.Global.createCookie('relationship_account', JSON.stringify(relationship_account_set_cookie), 365);
					if (response.relationship_account.token) {
						localStorage.setItem('token', response.relationship_account.token);
						localStorage.setItem('password', this.state.password);
					}
					window.Global.messageTop({
						node: window.$('body'),
						type: 'positive',
						message: 'เข้าสู่ระบบสำเร็จ'
					});
					if (!response.relationship_account.is_set_password) {
						window.location = '/register?token=' + response.relationship_account.token;
					}
					if (response.relationship_account.is_set_password) {
						window.location = '/payment?token=' + response.relationship_account.token;
					}
				}
			}
		});
	}

	render() {
		return (
			<div ref={(ref) => {
				this.$parent = window.$(ref);
			}} className="wetv_bg">
				<Header/>
				<div className="flex row center-xs padded">
					<div className="col-xs-16 col-lg-4 padded">
						<div className="ui segments card-center">
							<div className="ui segment secondary center-xs">
								ระบบตรวจสอบค่าบริการ
								<br/>บริษัท <span className="red-font">WETV</span>
							</div>
							<div className="ui segment">
								<form
									className="ui form start-xs"
									ref={(ref) => {
										this.form = ref;
									}}
									onSubmit={() => {
										// this.$parent.find('button').trigger('click');
										//console.log(this.$parent.find('button'));
									}}
								>
									<div className="field">
										<label>รหัสสมาชิก / อีเมล</label>
										<input type="text"
										       name="username"
											// defaultValue={this.state.username}
										/>
									</div>
									<div className="field">
										<label>รหัสผ่าน</label>
										<input type="password"
										       name="password"
											// defaultValue={this.state.password}
										/>
									</div>
									<div className="ui error message"/>
									<div className={"flex row"}>
										<button type="submit" className="ui button wetv-btn oval">ยืนยัน</button>
										<div className={"right floated middle-xs flex row"}>
											<Link className={"link"} to={"/forgot-password"}>ลืมรหัสผ่าน ?</Link>
										</div>
									</div>
								</form>
							</div>
							<div className="ui segment center-xs">
								มีข้อสงสัยโปรดติดต่อลูกค้าสัมพันธ์หมายเลข 053-287581-4
							</div>
							<div className="ui segment">
								<div className="flex row wrap padded ">
									<div className='col-xs-8 col-lg-8 form-group center-xs'>
										<img className="header_logo" src="assets/images/shield.png" alt="shield"/>
										<br/>
										ปกป้องข้อมูลการใช้งาน
									</div>
									<div className='col-xs-8 col-lg-8 form-group center-xs'>
										<img className="header_logo" src="assets/images/lock.png" alt="lock"/>
										<br/>
										เข้าใช้งานด้วยรหัสผ่าน
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}