import React from 'react';
import {Modal} from "../component/Modal";

export default class ChangePassword extends React.Component {
	constructor() {
		super();
		this.state = {
			password: "",
		}

	}

	render() {
		return (
			<div>
				<Modal className="ui tiny modal"
				       closable={false}
				       onShowToSetupForm={($modal) => {
					       $modal.find('form').form2('setup');
					       $modal.find('form').form2('submit', {
						       method: 'PUT',
						       url: window.host + '/api/v1/e-commerce/change-password',
						       beforeSent: function (object) {
							       return object;
						       },
						       loadingButton: $modal.find('.button.approve'),
						       onSuccess: (response) => {
							       if (response.success) {
								       window.Global.messageTop({
									       type: 'positive',
									       message: 'เปลี่ยนรหัสผ่านสำเร็จ'
								       });
								       this.props.onSuccess();
								       localStorage.setItem('password', this.new_password.val());
								       $modal.modal('hide');
							       }
						       }
					       });
					       $modal.find('.close.icon').remove();
				       }}
				>
					<div className="header">
						<div className="flex flex-row">
							เปลี่ยนรหัสผ่าน
						</div>
					</div>
					<div className="content">
						<form className={"ui form"}>
							<div className="field">
								<label>รหัสผ่านเดิม</label>
								<input type={"password"} name={"old_password"}/>
							</div>
							<div className="field">
								<label>รหัสผ่านใหม่</label>
								<input type={"password"} name={"new_password"}
								       ref={(ref) => {
									       this.new_password = window.$(ref)
								       }}
								/>
							</div>
							<div className="field">
								<label>ยืนยันรหัสผ่านใหม่</label>
								<input type={"password"} name={"confirm_new_password"}/>
							</div>

							<div className="field GoogleRecaptcha_root"/>
							<div className="ui error message"/>
						</form>
					</div>
					<div className="actions">
						<button className="ui deny oval button"
						        type={"button"}>
							ยกเลิก
						</button>
						<button className="ui approve oval button wetv-btn"
						        type={"button"}
						        style={{marginLeft: 15}}
						>
							ตกลง
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}