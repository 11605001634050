import React from 'react';
import './index.css';
import LoginWithUsernameAndPassword from './LoginWithUsernameAndPassword';
import SaleOrderItemMonthly from './SaleOrderItemMonthly';
import SaleOrderPaymentMethod from './SaleOrderPaymentMethod';
import SaleOrderPaymentByBankTransfer from './SaleOrderPaymentByBankTransfer';
import SaleOrderPaymentByCreditCard from './SaleOrderPaymentByCreditCard';
import SaleOrderPaymentByQRcode from './SaleOrderPaymentByQRcode';
import PaymentSuccess from './PaymentSuccess';
import RegisterFirstTime from './RegisterFirstTime';
import ForgotPassword from './ForgotPassword';
import PasswordReset from './PasswordReset';
// import ConfirmRegister from './ConfirmRegister';
import PaymentSuccessInfo from './PaymentSuccessInfo';
import {BrowserRouter as Router, Route} from "react-router-dom";
import {Global2} from "./component/Global";
import ChangeEmail from "./ChangeEmail";
import ConfirmEmail from "./ConfirmEmail";

window.is_production = true;
window.host = '';
if (window.location.host.indexOf('localhost') !== -1
	|| window.location.host.indexOf('wetv-payment-checking.b49consultancy.com') !== -1) {
	window.is_production = false;
	window.host = 'https://wetv-payment-checking.b49consultancy.com';
}

window.Global.ajax = function (object) {
	if (typeof (object.method) == 'undefined')
		object.method = 'POST';
	if (typeof (object.dataType) == 'undefined')
		object.dataType = 'json';
	if (typeof (object.contentType) == 'undefined')
		object.contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
	if (object.data) {
		//object.data.api_key = '3WEgGuFJpPedJX84sbAzaWxv';
	} else {
		object.data = {};
	}
	//if (object.data && localStorage.user) {
	//	var user = JSON.parse(localStorage.user);
	//	object.data.access_token = user.user.access_token;
	//}
	//if (localStorage.user) {
	//	object.data.users_id = Global.user.user.id;
	//	object.data.access_token = Global.user.user.access_token;
	//}

	let headers = object.headers ? object.headers : {};
	if (Global2.getMyProfile()) {
		headers['X-B49-CLIENT'] = localStorage.getItem('token');
	}

	return window.$.ajax({
		type: object.method,
		data: object.data,
		contentType: object.contentType,
		url: object.url,
		headers: headers,
		beforeSend: function () {
			if (typeof (object.beforeSend) != 'undefined') {
				object.beforeSend();
			}
		},
		dataType: object.dataType
	}).done(function (response, textStatus, jqXHR) {
		// console.log(response);
		if (response.error && response.message) {
			window.$.each(response.message, function (i, message) {
				if (message['is_require_authentication']) {
					window.location = '/signout';
					return false;
				}
			})
		}
		if (typeof object.done == 'function')
			object.done(response);
	}).fail(function (jqXHR, textStatus, errorThrown) {
		//console.log(jqXHR, typeof object.fail == 'function');
		if (typeof object.fail == 'function')
			object.fail(jqXHR);
	});
};

let url = window.location.pathname;
window.segment = url.split('/');


class App extends React.Component {
	componentDidMount() {
		window.Global['google_recaptcha_key'] = '6LeZEUMUAAAAAI24cCUQrhhGGEkFH2EvWTiMK5xf';
	}

	render() {
		return (
			<Router>
				<Route path="/" exact component={LoginWithUsernameAndPassword}/>
				<Route path="/password-reset" exact component={PasswordReset}/>
				<Route path="/forgot-password" exact component={ForgotPassword}/>
				<Route path="/register" exact component={RegisterFirstTime}/>
				<Route path="/change-email" exact component={ChangeEmail}/>
				<Route path="/comfirm-change-email" exact component={ChangeEmail}/>
				<Route path="/confirm-email" exact component={ConfirmEmail}/>
				<Route path="/payment" exact component={SaleOrderItemMonthly}/>
				<Route path="/payment-method" exact component={SaleOrderPaymentMethod}/>
				<Route path="/bill-payment" exact component={SaleOrderPaymentByBankTransfer}/>
				<Route path="/qr-code" exact component={SaleOrderPaymentByQRcode}/>
				<Route path="/payment-success" exact component={PaymentSuccess}/>
				<Route path="/payment-info" exact component={PaymentSuccessInfo}/>
				<Route path="/credit-card-payment" exact component={SaleOrderPaymentByCreditCard}/>
			</Router>
		);
	}
}

export default App;
