import React from 'react';
import Helper from "./Helper";
import Header from "./Header";
// import {Modal} from "react-spaceship-web";
// import Helper from "./Helper";

export default class SaleOrderPaymentByCreditCard extends React.Component {
	constructor() {
		super();
		let payment_withholding_tax = window.Global.getCookie('payment_withholding_tax') && window.Global.getCookie('payment_withholding_tax') !== "" ? JSON.parse(window.Global.getCookie('payment_withholding_tax')) : false;
		this.state = {
			gbToken: false,
			is_loading: true,
			amount: window.Global.getAllQueryStrings().amount,
			route_id: window.Global.getAllQueryStrings().route_id,
			payment: window.Global.getAllQueryStrings().amount || 0,
			total_net_price: window.Global.getAllQueryStrings().amount || 0,
			token: window.Global.getAllQueryStrings().token || '',
			selected_sale_order_contacts: [],
			sale_order_contacts: [],
			selected_items: [],
			payment_withholding_tax: payment_withholding_tax ? payment_withholding_tax.payment_withholding_tax : false,
			is_create_payment_withholding_tax: payment_withholding_tax ? payment_withholding_tax.is_create_payment_withholding_tax : false,
		}
	}

	componentDidMount() {
		window.Global.ajax({
			method: 'GET',
			url: window.host + '/api/v1/e-commerce/relationship/payment-summary',
			data: {
				token: this.state.token,
				password: localStorage.getItem('password'),
			},
			done: (response) => {
				if (response.error) {
					window.location = '/?token=' + this.state.token;
				}
				if (!response.success) {
					return false;
				}
				let sale_order_contacts = Helper.setSelectedItem(response.sale_order_contacts, this.state.route_id);

				let _response = response;
				window.Global.ajax({
					method: 'GET',
					url: window.host + '/api/v1/e-commerce/payment_by_bank_transfer_draft/bank',
					data: {
						token: this.state.token,
						password: localStorage.getItem('password'),
					},
					done: (response) => {
						if (response.error) {
							window.location = '/?token=' + this.state.token;
						}
						if (!response.success) {
							return false;
						}
						this.setState({
							relationship: _response.relationship,
							selected_items: Helper.sumTotalPayment(sale_order_contacts).selected_items,
							sale_order_contacts: Helper.sumTotalPayment(sale_order_contacts).sale_order_contacts,
							selected_sale_order_contacts: Helper.sumTotalPayment(sale_order_contacts).selected_sale_order_contacts,
							isLoading: false,
						}, () => {
							this.callCreditCardForm();
							this.checkGBToken();
						});
					}
				});
			}
		});
	}


	callCreditCardForm = () => {
		// console.log("callCreditCardForm");
		let publicKey = "9MPK0GEnZksZv4vyls0Vf8rdLC8AugcO";
		if (window.location.hostname === 'accounts.wetv.co.th') {
			publicKey = 'bD52ETYG50wXN3SojEoefNbeHpJJKlh1';
		}
		new window.GBPrimePay({
			publicKey: publicKey,
			gbForm: '#gb-form',
			merchantForm: '#checkout-form',
			amount: this.state.amount,
			customStyle: {backgroundColor: '#eaeaea'},
			env: 'prd'
		});
		this.setState({
			is_loading: false
		})
	};

	componentWillUnmount() {
		window.clearInterval(window.gb_token);
	}

	checkGBToken() {
		window.gb_token = window.setInterval(() => {
			let gbToken = false;
			if (window.$('input[name="gbToken"]')) {
				gbToken = window.$('input[name="gbToken"]').val();
			}
			if (gbToken) {
				if (this.state.gbToken !== gbToken) {
					this.setState({
						gbToken: gbToken,
						is_loading: true,
					}, () => {
						this.paymentForm.trigger('submit');
					});
				}
			}
		}, 2000);
	}


	render() {
		return (
			<div className='full-bg padded wetv_bg flex'>
				<Header/>
				<div className="flex row padded center-xs">
					<div className="col-xs-16 col-lg-6 padded" id={"paymentMethodRoot"}>
						<div
							className={this.state.is_loading ? "ui segments loading" : "ui segments"}
							style={{padding: 0, backgroundColor: '#eaeaea'}}>

							<div className="ui segment row middle-xs">
								<strong>ยอดที่ต้องชำระ</strong>
								<div className={['right floated']}>
									<span>฿ </span>
									<span
										className="lg-font font-bold">{this.state.amount.format(2, 3, {pretty: false})}</span>
								</div>
							</div>
							<form className={"ui form"}
							      style={{paddingLeft: '1rem', paddingRight: '1rem'}}
							      ref={(ref) => {
								      this.paymentForm = window.$(ref);
								      this.paymentForm.form2('setup');
								      this.paymentForm.form2('submit', {
									      method: 'POST',
									      url: window.host + '/api/v1/e-commerce/payment',
									      beforeSent: (data) => {
										      let _data = Helper.preparePaymentData(this.state.sale_order_contacts, {
											      payment_by_gbprimepay_credit_card: {
												      gb_token: this.state.gbToken,
												      gb_remember_card: window.$('input[name="gbRememberCard"]').val(),
											      },

											      amount: this.state.amount,
											      payment_by: 'gbprimepay',
											      type: 'credit_card'
										      });
										      data.is_commit = 1;
										      data = {...data, ..._data};
										      data.payment_withholding_tax = this.state.payment_withholding_tax;
										      data.is_create_payment_withholding_tax = this.state.is_create_payment_withholding_tax ? 1 : 0;
										      // console.log(data);
										      // return;
										      return data;
									      },
									      // loadingButton: window.$(ref).find('.button'),
									      onSuccess: (response) => {
										      // console.log(response);
										      // return;
										      if (response.error) {
											      this.setState({
												      is_loading: false,
											      });
										      }
										      if (response.success && response.redirect_form) {
											      let input_data = [];
											      for (let _data in response.redirect_form.data) {
												      if (response.redirect_form.data.hasOwnProperty(_data)) {
													      input_data.push({
														      name: _data,
														      value: response.redirect_form.data[_data]
													      });
												      }
											      }
											      this.setState({
												      response: response,
												      input_data: input_data,
											      }, () => {
												      this.submitForm.submit();
											      })
										      }
									      },
									      onError: (response) => {
										      this.setState({
											      is_loading: false,
										      });
									      }
								      });
							      }}
							>
								<div className="field GoogleRecaptcha_root" style={{margin: 0}}/>
								<div className={"ui error message"}/>
							</form>
							<div id="checkout-form">
								<div id="gb-form" style={{height: 500}}/>
							</div>

							<div className="ui segment">
								<a className="ui button oval"
								   ref={(ref) => {
									   this.cancel_btn = ref;
								   }}
								   onClick={() => {
									   window.$(this.cancel_btn).addClass('loading');
									   setTimeout(() => {
										   window.$(this.cancel_btn).removeClass('loading');
									   }, 5000)
								   }}
								   href={'/payment-method?amount=' + this.state.amount + '&route_id=' + this.state.route_id + '&token=' + this.state.token}>
									กลับไปเลือกวิธีชำระเงิน
								</a>
							</div>
							{
								this.state.response ?
									<form action={this.state.response.redirect_form.url} method={"POST"}
									      ref={(ref) => {
										      this.submitForm = window.$(ref);
									      }}
									>
										{
											this.state.input_data ?
												this.state.input_data.map((input_data) => {
													return (
														<input type={"hidden"} name={input_data.name}
														       value={input_data.value}/>
													);
												})
												: null
										}
									</form>
									: null
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}