import React from 'react';
import Header from './Header';

export default class PaymentSuccess extends React.Component {
	constructor(props) {
		super(props);
		let token = '';
		if (window.Global.getAllQueryStrings().token) {
			token = window.Global.getAllQueryStrings().token;
		}
		if (localStorage.getItem('token')) {
			token = localStorage.getItem('token');
		}
		this.state = {
			amount: window.Global.getAllQueryStrings().amount || 0,
			token: token,
			payment_by: window.Global.getAllQueryStrings().payment_by || 'bank_transfer',
			gbpReferenceNo: window.Global.getAllQueryStrings().gbpReferenceNo || false,
			is_loading: true,
			is_success: false,
		}
	}

	componentDidMount() {

		if (this.state.payment_by === "gbprimepay_credit_card") {
			this.creditCardPaymentChecking();

		}

		if (this.state.payment_by !== "gbprimepay_credit_card") {
			let setTime = setTimeout(() => {
				this.setState({
					is_loading: false,
					is_success: true
				})
				clearTimeout(setTime);
			}, 1000)
		}
	}

	creditCardPaymentChecking() {
		let setTime = setTimeout(() => {
			window.Global.ajax({
				method: 'GET',
				url: window.host + '/api/v1/e-commerce/payment',
				data: {
					// is_commit: 0,
					payment_by_gbprimepay_gbp_reference_no: this.state.gbpReferenceNo,
				},
				done: (response)=> {
					let is_success = false;
					if (response.payment.type === 'pay_in_sale_order') {
						is_success = true;
					}
					this.setState({
						is_loading: false,
						is_success: is_success
					})
				}
			})
			clearTimeout(setTime);
		}, 10000)
	}

	render() {
		return (
			<div className='full-bg padded wetv_bg flex'>
				<Header/>
				<div className="flex row padded center-xs">
					<div className="col-xs-16 col-lg-6 padded">
						<div className="ui segments">
							{
								this.state.is_loading ?
									<div className="ui segment">
										<div className="ui active centered inline large loader"/>
										<div className={"flex row center-xs"}
										     style={{
											     marginTop: 10,
											     fontWeight: 'bold',
											     fontSize: 20,
											     marginBottom: 10
										     }}>
											กำลังตรวจสอบการชำระเงิน
										</div>
									</div>
									:
									null
							}
							{
								!this.state.is_loading
								&& this.state.is_success ?
									<div className="ui segment">
										<div className="flex middle-xs">
											<h2 className={"ui header"} style={{
												marginBottom: 0,
												marginTop: 5
											}}>{this.state.payment_by === 'bank_transfer' ? "แจ้งการโอนเงินสำเร็จ" : "ชำระเงินสำเร็จ"}</h2>
											<div className="payment-success-icon">
												{/*<img className="item-image" src="assets/images/checked.png"*/}
												{/*     alt="checked"/>*/}
												<svg version="1.1" xmlns="http://www.w3.org/2000/svg"
												     viewBox="0 0 130.2 130.2">
													<circle className="path circle" fill="none" stroke="#73AF55"
													        strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1"
													        r="62.1"/>
													<polyline className="path check" fill="none" stroke="#73AF55"
													          strokeWidth="6" strokeLinecap="round"
													          strokeMiterlimit="10"
													          points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
												</svg>
											</div>
											<div className="payment-push-bottom">
												<span>฿ </span>
												<span
													className="lg-font font-bold">{this.state.amount.format(2, 3, {pretty: false})}</span>
											</div>
											<a className="ui button yellow basic oval fluid payment-push-bottom"
											   href={"/payment?token=" + this.state.token}>กลับสู่หน้าหลัก</a>
										</div>
										{
											this.state.payment_by === 'bank_transfer' ?
												<>
													<div className="ui divider"/>
													<div className="row center-xs">
														WETV ได้รับการแจ้งการโอนเงินของคุณแล้ว <br/>
														โดยจะตรวจสอบภายใน 3 วัน
													</div>
												</>
												: null
										}
									</div>
									: null
							}
							{
								!this.state.is_loading
								&& !this.state.is_success ?
									<div className="ui segment">
										<div className="flex middle-xs">
											<h2 className={"ui header"} style={{
												marginBottom: 0,
												marginTop: 5
											}}>ชำระเงินไม่สำเร็จ</h2>
											<div className="payment-success-icon">
												{/*<img className="item-image" src="assets/images/checked.png"*/}
												{/*     alt="checked"/>*/}
												<svg version="1.1" xmlns="http://www.w3.org/2000/svg"
												     viewBox="0 0 130.2 130.2">
													<circle className="path circle" fill="none" stroke="#D06079"
													        strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1"
													        r="62.1"/>
													<line className="path line" fill="none" stroke="#D06079"
													      strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"
													      x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
													<line className="path line" fill="none" stroke="#D06079"
													      strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"
													      x1="95.8" y1="38" x2="34.4" y2="92.2"/>
												</svg>
											</div>
											<a className="ui button yellow basic oval fluid payment-push-bottom"
											   href={"/payment?token=" + this.state.token}>กลับไปหน้าวิธีการชำระเงิน</a>
										</div>
										{
											this.state.payment_by === 'bank_transfer' ?
												<>
													<div className="ui divider"/>
													<div className="row center-xs">
														WETV ได้รับการแจ้งการโอนเงินของคุณแล้ว <br/>
														โดยจะตรวจสอบภายใน 3 วัน
													</div>
												</>
												: null
										}
									</div>
									: null
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}