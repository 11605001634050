import React from 'react';
import Header from "./Header";
import Helper from "./Helper";

export default class SaleOrderPaymentByBankTransfer extends React.Component {
	constructor(props) {
		super(props);
		let payment_withholding_tax = window.Global.getCookie('payment_withholding_tax') && window.Global.getCookie('payment_withholding_tax') !== "" ? JSON.parse(window.Global.getCookie('payment_withholding_tax')) : false;
		this.state = {
			amount: window.Global.getAllQueryStrings().amount,
			route_id: window.Global.getAllQueryStrings().route_id,
			payment: window.Global.getAllQueryStrings().amount || 0,
			total_net_price: window.Global.getAllQueryStrings().amount || 0,
			hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
			minute: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
				'11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
				'21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
				'31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
				'41', '42', '43', '44', '45', '46', '47', '48', '49', '50',
				'51', '52', '53', '54', '55', '56', '57', '58', '59'],
			isBankLoading: true,
			token: window.Global.getAllQueryStrings().token || '',
			selected_sale_order_contacts: [],
			sale_order_contacts: [],
			selected_items: [],
			payment_withholding_tax: payment_withholding_tax ? payment_withholding_tax.payment_withholding_tax : false,
			is_create_payment_withholding_tax: payment_withholding_tax ? payment_withholding_tax.is_create_payment_withholding_tax : false,
		}
	}

	componentDidMount() {
		window.Global.ajax({
			method: 'GET',
			url: window.host + '/api/v1/e-commerce/relationship/payment-summary',
			data: {
				token: this.state.token,
				password: localStorage.getItem('password'),
			},
			done: (response) => {
				if (response.error) {
					window.location = '/?token=' + this.state.token;
				}
				if (!response.success) {
					return false;
				}
				let sale_order_contacts = Helper.setSelectedItem(response.sale_order_contacts, this.state.route_id);

				let _response = response;
				window.Global.ajax({
					method: 'GET',
					url: window.host + '/api/v1/e-commerce/payment_by_bank_transfer_draft/bank',
					data: {
						token: this.state.token,
						password: localStorage.getItem('password'),
					},
					done: (response) => {
						if (response.error) {
							window.location = '/?token=' + this.state.token;
						}
						if (!response.success) {
							return false;
						}
						this.setState({
							bank_list: response.bank,
							relationship: _response.relationship,
							selected_items: Helper.sumTotalPayment(sale_order_contacts).selected_items,
							sale_order_contacts: Helper.sumTotalPayment(sale_order_contacts).sale_order_contacts,
							selected_sale_order_contacts: Helper.sumTotalPayment(sale_order_contacts).selected_sale_order_contacts,
							isLoading: false,
							isBankLoading: false
						});
					}
				});
			}
		});
	}

	render() {
		let current_hour = new Date().getHours();
		let current_minute = new Date().getMinutes();
		return (
			<div className='full-bg padded wetv_bg flex'>
				<Header/>
				<div className="flex row padded center-xs">
					<div className="col-xs-16 col-lg-6 padded">
						<form className="ui form segments"
						      ref={(ref) => {
							      let amount = 0;
							      window.$(ref).form2('setup');
							      window.$(ref).form2('submit', {
								      method: 'POST',
								      url: window.host + '/api/v1/e-commerce/payment',
								      beforeSent: (data) => {
									      // data.token = this.state.token;
									      // data.password = localStorage.getItem('password');
									      data.is_commit = 1;
									      data.items = [];
									      Helper.sumTotalPayment(this.state.sale_order_contacts).selected_items.forEach((selected_item) => {
										      data.items.push(selected_item.data);
									      });
									      data.payment_withholding_tax = this.state.payment_withholding_tax;
									      data.is_create_payment_withholding_tax = this.state.is_create_payment_withholding_tax ? 1 : 0;
									      data.payment_by_bank_transfer_draft.time_transfer = data.hour + ':' + data.minute;
									      delete data.hour;
									      delete data.minute;
									      amount = data.payment_by_bank_transfer_draft.total_net_price;
									      // data.amount = this.state.payment;
									      return data;
								      },
								      loadingButton: window.$(ref).find('.button'),
								      onSuccess: (response) => {
									      // window.Global.messageTop({
										  //     node: window.$('body'),
										  //     type: 'positive',
										  //     message: 'ขอบคุณมาก แจ้งการโอนเงินเรียบร้อยแล้ว โปรดรอการตรวจสอบจากเจ้าหน้าที่'
									      // });
									      window.location = '/payment-success?amount=' + amount + '&token=' + this.state.token;
								      }
							      });
						      }}>
							{/*<input type="hidden" name="total_net_price"*/}
							{/*value={window.Global.getAllQueryStrings().amount}/>*/}
							{/*PAYMENT INFO*/}
							<div className="ui segment row middle-xs">
								<strong>ยอดที่ต้องชำระ</strong>
								<div className={['right floated']}>
									<span>฿ </span>
									<span
										className="lg-font font-bold">{this.state.payment.format(2, 3, {pretty: false})}</span>
								</div>
							</div>
							{/*BANK TITLE*/}
							<div className="ui segment inverted custom-title" style={{textAlign: 'center'}}>
								<span>เลือกธนาคาร</span>
							</div>
							{
								this.state.isBankLoading ?
									<div className="ui segment">
										<div className="ui active centered inline loader"/>
									</div>
									:
									<>
										<div className="ui segment custom-segment warning grey-bg">
											<div className="row middle-xs">
												<div
													style={{
														flexShrink: 0,
														paddingRight: '0.5rem',
														width: 27,
														textAlign: 'center'
													}}/>
												<div
													style={{
														flexShrink: 0,
														paddingRight: '0.5rem',
														width: 45,
														textAlign: 'center'
													}}/>
												<div className="flex row wrap" style={{flexGrow: 1}}>
													<div className="col-xs-8 col-lg-4 padded">
														ธนาคาร
													</div>
													<div className="col-xs-8 col-lg-3 padded">
														สาขา
													</div>
													<div className="col-xs-8 col-lg-3 padded">
														ประเภทบัญชี
													</div>
													<div className="col-xs-8 col-lg-3 padded">
														เลขที่บัญชี
													</div>
													<div className="col-xs-8 col-lg-3 padded">
														ชื่อบัญชี
													</div>
												</div>
											</div>
										</div>
										{/*BANK*/}
										{
											this.state.bank_list ?
												this.state.bank_list.map((bank, i) =>
													<label key={i}
													       className="ui segment custom-segment warning bank-list">
														<div className="row middle-xs">
															<div className={"flex middle-xs"}
															     style={{flexShrink: 0, paddingRight: '0.5rem'}}>
																<div className="ui radio checkbox" ref={(ref) => {
																	window.$(ref).checkbox()
																}}>
																	<input type="radio"
																	       name="payment_by_bank_transfer_draft[bank_id]"
																	       defaultChecked={i === 0}
																	       value={bank.id}/>
																</div>
															</div>
															<div className={"flex middle-xs"}
															     style={{flexShrink: 0, paddingRight: '0.5rem'}}>
																<img src={bank.logo_url} className="bank-logo"
																     alt={bank.code_name}/>
															</div>
															<div className="flex row wrap middle-xs"
															     style={{flexGrow: 1}}>
																<div className="col-xs-8 col-lg-4 padded">
																	{bank.code_name}
																</div>
																<div className="col-xs-8 col-lg-3 padded">
																	{bank.branch_name}
																</div>
																<div className="col-xs-8 col-lg-3 padded">
																	{bank.type}
																</div>
																<div className="col-xs-8 col-lg-3 padded">
																	{bank.no}
																</div>
																<div className="col-xs-8 col-lg-3 padded">
																	{bank.name}
																</div>
															</div>
														</div>
													</label>
												)
												: null
										}
									</>
							}

							<div className="ui segment inverted custom-title" style={{textAlign: 'center'}}>
								<span>กรอกรายละเอียดการโอนเงิน</span>
							</div>
							<div className="ui segment">
								<div className="row middle-xs field field-inline">
									<div className="col-xs-5 col-lg-4">
										<label>อัพโหลดรูปสลิป</label>
									</div>
									<div className="col-xs-11 col-lg-10">
										<div id="Upload_file"
										     data-form2_setup="true"
										     data-form2_type="upload"
										     data-single_file_upload="true"
										     data-url={window.is_production ? "https://b49erp.com/api/file/upload" : "https://dev.b49erp.com/api/file/upload"}
										     data-input_name="payment_by_bank_transfer_draft[payment_by_bank_transfer_draft_file_upload]"
										/>
									</div>
								</div>
								<div className="row middle-xs field field-inline">
									<div className="col-xs-5 col-lg-4">
										<label>วันที่โอนเงิน</label>
									</div>
									<div className="col-xs-11 col-lg-10">
										<div data-form_calendar="date"
										     data-input_name="payment_by_bank_transfer_draft[date_transfer]"
										     data-placeholder="วันที่โอนเงิน"
										     data-locale="th"
										     data-initialdate="now"
										/>
									</div>
								</div>

								<div className="row middle-xs field field-inline">
									<div className="col-xs-5 col-lg-4">
										<label>เวลาโอนเงิน</label>
									</div>
									<div className="col-xs-11 col-lg-10">
										<div className="ui right labeled input middle-xs">
											<select className="ui dropdown input-custom-bg"
											        name="hour"
											        defaultValue={this.state.hours[current_hour]}>
												<option value="">ชั่วโมง</option>
												{
													this.state.hours.map((hour, i) => {
														return <option key={i}>{hour}</option>
													})
												}
											</select>
											<span style={{
												fontSize: 20,
												marginLeft: 10,
												marginRight: 10,
												marginTop: -5,
												display: 'inline-block'
											}}> : </span>
											<select className="ui dropdown input-custom-bg"
											        name="minute"
											        defaultValue={this.state.minute[current_minute]}>
												<option value="">นาที</option>
												{
													this.state.minute.map((hour, i) => {
														return <option value={hour} key={i}>{hour}</option>
													})
												}
											</select>
										</div>
									</div>
								</div>
								<div className="row middle-xs field field-inline">
									<div className="col-xs-5 col-lg-4">
										<label>จำนวนเงิน</label>
									</div>
									<div className="col-xs-11 col-lg-10">
										<div className="ui right labeled input">
											<input type="number"
											       defaultValue={0 + ''}
											       step="any"
											       name="payment_by_bank_transfer_draft[total_net_price]"
											       onFocus={(event) => event.target.select()}
											       className={"custom-input"}
											/>
											<div className="ui label" style={{marginLeft: '-.833em'}}>บาท</div>
										</div>

									</div>
								</div>

								<div className="row field field-inline">
									<div className="col-xs-5 col-lg-4">
										<label>หมายเหตุ</label>
									</div>
									<div className="col-xs-11 col-lg-10">
										<textarea name="payment_by_bank_transfer_draft[remark]" rows="1"/>
									</div>
								</div>
								<div className="field GoogleRecaptcha_root" style={{margin: 0}}/>
								<div className="ui error message"/>
							</div>
							<div className="ui segment row between-xs">
								<a className="ui button oval"
								   ref={(ref) => {
									   this.cancel_btn = ref;
								   }}
								   onClick={() => {
									   window.$(this.cancel_btn).addClass('loading');
									   setTimeout(() => {
										   window.$(this.cancel_btn).removeClass('loading');
									   }, 5000)
								   }}
								   href={'/payment-method?amount=' + this.state.amount + '&route_id=' + this.state.route_id + '&token=' + this.state.token}>
									กลับไปเลือกวิธีชำระเงิน
								</a>
								<button className="wetv-btn ui button oval" type="submit">ยืนยัน</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}