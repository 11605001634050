import React from 'react';
import Header from "./Header";
import {Link} from 'react-router-dom';

export default class LoginWithUsernameAndPassword extends React.Component {

	constructor() {
		super();
		this.state = {
			password: "1234",
			username: '',
			qs: window.Global.getAllQueryStrings()
		};
		localStorage.removeItem('password');
		window.Global.createCookie('relationship_account', '', 365);
	}

	componentDidMount() {
	}

	render() {
		return (
			<div ref={(ref) => {
				this.$parent = window.$(ref);
			}} className="wetv_bg">
				<Header/>
				<div className="flex row center-xs padded">
					<div className="col-xs-16 col-lg-4 padded">
						{
							this.state.show_message ?
								<>
									<div className="ui icon message green">
										<i className="paper plane icon"/>
										<div className="content">
											<div className="header">
												{this.state.show_message}
												<div>
													<Link to={"/"} className={"link"}>เข้าสู่ระบบ</Link>
												</div>
											</div>
										</div>
									</div>
								</>
								:
								<div className="ui segments card-center">
									<div className="ui segment secondary center-xs">
										ตั้งรหัสผ่านใหม่
									</div>
									<div className="ui segment">
										<div className={"center-xs"} style={{marginBottom: 10}}>
											ระบุรหัสผ่านใหม่
										</div>
										<form
											className="ui form start-xs"
											ref={(ref) => {
												this.form = ref;
												window.$(this.form).form2('setup');
												window.$(this.form).form2('submit', {
													url: window.host + '/api/v1/e-commerce/password-reset',
													beforeSent: (data) => {
														return data;
													},
													loadingButton: window.$(this.form).find('button[type="submit"]'),
													onSuccess: (response) => {
														this.setState({show_message: response.message});
													}
												});
											}}
										>
											<div className="field">
												<label>รหัสผ่านใหม่</label>
												<input type="password"
												       name="new_password"
													// defaultValue={this.state.password}
												/>
											</div>
											<div className="field">
												<label>ยืนยันรหัสผ่านใหม่</label>
												<input type="password"
												       name="confirm_new_password"
													// defaultValue={this.state.password}
												/>
											</div>
											<input type={"hidden"} name={"token"} value={this.state.qs.t}/>
											<div className="field GoogleRecaptcha_root" style={{margin: 0}}/>
											<div className="ui error message"/>
											<div className={"flex row"}>
												<button type="submit" className="ui button wetv-btn oval">ยืนยัน
												</button>
											</div>
										</form>
									</div>
								</div>
						}
					</div>
				</div>
			</div>
		);
	}
}