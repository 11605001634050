import React from 'react';
import {Modal} from "../component/Modal";

export default class ChangePhoneNumber extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			otp_form: false,
			message_form: false,
		};
	}


	render() {
		return (
			<div>
				<Modal className="ui tiny modal"
				       onShowToSetupForm={($modal) => {
					       $modal.find('form').form2('setup');
					       $modal.find('.close.icon').remove();
					       $modal.find('form').form2('submit', {
						       method: 'PUT',
						       url: window.host + '/api/v1/e-commerce/relationship_account/change_phone_number',
						       beforeSent: (object) => {
							       return object;
						       },
						       loadingButton: $modal.find('.button.approve'),
						       onSuccess: (response) => {
							       // console.log(response);
							       if (response.success) {
								       this.setState({
									       message_form: response.message
								       })
							       }
							       window.Global.messageTop({
								       message: 'เปลี่ยนเบอร์โทรศัพท์สำเร็จ'
							       });
							       this.props.onSuccess();
							       $modal.modal('hide');
						       }
					       });
				       }}
				>
					<div className="header">
						<div className="flex flex-row">
							เปลี่ยนเบอร์โทรศัพท์มือถือ
						</div>
					</div>
					<div className="content">
						{
							this.state.message_form ?
								<div className="ui success icon message">
									<i className="paper plane icon"/>
									<div className="content">
										<div className="header">
											{this.state.message_form}
										</div>
									</div>
								</div>
								: null
						}
						{
							!this.state.message_form ?
								<form className={"ui form"}>
									{
										this.props.old_phone_number ?
											<div className="field">
												<label>เบอร์โทรศัพท์มือถือเดิม</label>
												<input type={"text"} name={"old_phone_number"}
												       disabled={true}
												       defaultValue={this.props.old_phone_number}/>
											</div>
											: null
									}
									<div className="field">
										<label>เบอร์โทรศัพท์มือถือ{this.props.old_phone_number? 'ใหม่' : null}</label>
										<input type={"text"} name={"phone_number"}/>
									</div>
									<div className="field GoogleRecaptcha_root"/>
									<div className="ui error message"/>
								</form>
								: null
						}
					</div>
					<div className="actions">
						<button className="deny ui button oval"
						        type={"button"}>
							{
								!this.state.message_form ?
									'ยกเลิก'
									: 'ปิด'
							}
						</button>
						{
							!this.state.message_form ?
								<button className="approve ui button wetv-btn oval"
								        type={"button"}
								        style={{marginLeft: 15}}
								>
									ตกลง
								</button>
								: null
						}
					</div>
				</Modal>
			</div>
		);
	}
}