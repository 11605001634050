import React from 'react';
import Header from "./Header";

export default class ChangeEmail extends React.Component {
	constructor() {
		super();
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			form_mode: window.Global.getAllQueryStrings().p,
			form_message: false,
			form_verify_captcha: false,
			is_was_click: false,
		};

	}

	componentDidMount() {
		if (window.segment[1] && window.segment[1] === 'change-email') {
			window.Global.ajax({
				method: 'POST',
				url: window.host + '/api/v1/e-commerce/old-email-verify-to-change-email',
				data: {
					token: window.Global.getAllQueryStrings().t,
				},
				done: (response) => {
					if (response.success) {
						this.setState({
							form_message: response.message,
							form_verify_captcha: false,
						})
					}
					if (response.error) {
						this.setState({
							form_verify_captcha: response.message[0].error_message,
						});
					}
				}
			});
		}
		if (window.segment[1] && window.segment[1] === 'comfirm-change-email') {
			window.Global.ajax({
				method: 'POST',
				url: window.host + '/api/v1/e-commerce/confirm-change-email',
				data: {
					token: window.Global.getAllQueryStrings().t,
				},
				done: (response) => {
					if (response.success) {
						this.setState({
							form_message: response.message,
							form_verify_captcha: false,
						})
					}
					if (response.error) {
						// console.log(response.message);
						this.setState({
							form_verify_captcha: response.message[0].error_message,
						});
					}
				}
			});
		}
	}


	render() {
		return (
			<div ref={(ref) => {
				this.$parent = window.$(ref);
			}} className="wetv_bg">
				<Header/>
				<div className="flex row padded center-xs">
					<div className="col-xs-16 col-lg-6 padded">
						{/*<div className="ui divider" style={{marginTop: 0}}/>*/}
						<form className={"login-bg"}
						      style={{backgroundImage: "url('./login-bg.jpg')", backgroundColor: '#f4f4f4'}}>
							{
								this.state.form_message ?
									<div className="ui success icon message">
										<i className="check icon"/>
										<div className="content">
											<h1 className="ui header" style={{textAlign: 'left'}}>
												ยืนยันเปลี่ยนอีเมล
											</h1>
											<p>{this.state.form_message}</p>
										</div>
									</div>
									: null
							}
							{
								!this.state.form_verify_captcha
								&& !this.state.form_message ?
									<div className="ui info icon message">
										<i className="notched circle loading icon"/>
										<div className="content">
											<h1 className="ui header" style={{textAlign: 'left'}}>
												ยืนยันเปลี่ยนอีเมล
											</h1>
											<p>ระบบกำลังทำการยืนยันเปลี่ยนอีเมล</p>
										</div>
									</div>
									: null
							}
							{
								this.state.form_verify_captcha ?
									<form className={"ui form segment"}
									      ref={(ref) => {
										      let post_url = false;
										      if (window.segment[1] && window.segment[1] === 'change-email') {
											      post_url = window.host + '/api/v1/e-commerce/old-email-verify-to-change-email';
										      }
										      if (window.segment[1] && window.segment[1] === 'comfirm-change-email') {
											      post_url = window.host + '/api/v1/e-commerce/confirm-change-email';
										      }
										      window.$(ref).form2('setup');
										      window.$(ref).form2('submit', {
											      method: 'POST',
											      url: post_url,
											      beforeSent: (object) => {
												      return object;
											      },
											      loadingButton: this.$parent.find('form').find('.button.approve'),
											      onSuccess: (response) => {
												      if (response.success) {
													      this.setState({
														      message_form: response.message,
														      form_verify_captcha: false,
													      });
													      if (response.error) {
														      this.setState({
															      form_verify_captcha: response.error_message,
														      });
													      }
												      }
											      }
										      });
									      }}
									>
										<h3 className={"ui header"}>{this.state.form_verify_captcha}</h3>
										<input type={"hidden"} name={"token"}
										       value={window.Global.getAllQueryStrings().t}/>
										<div className="field GoogleRecaptcha_root"/>
										<div className="ui error message"/>
										<button type={"submit"}
										        className={"ui button wetv-btn oval approve"}>
											ยืนยัน
										</button>
									</form>
									: null
							}
						</form>
					</div>
				</div>
			</div>

		);
	}
}
