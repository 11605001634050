import React from 'react';
import Header from "./Header";
import {Global2} from "./component/Global";
import {Link} from "react-router-dom";

export default class ConfirmEmail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			qs: window.Global.getAllQueryStrings(),
			form_mode: window.Global.getAllQueryStrings().p,
			form_message: false,
			form_verify_captcha: false,
			is_was_click: false,
		};
	}

	componentDidMount() {
		this.confirmEmail();
	}

	confirmEmail() {
		window.Global.ajax({
			url: window.host + '/api/v1/e-commerce/submit_confirm_email',
			method: 'POST',
			data: {token: window.Global.getAllQueryStrings().t},
			done: (response) => {
				// console.log(response);
				if (response.success) {
					this.setState({
						form_message: response.message,
						form_verify_captcha: false,
					})
				}
				if (response.error) {
					this.setState({
						form_verify_captcha: response.message[0].error_message,
					});
				}
			}
		})
	}

	render() {
		return (
			<div ref={(ref) => {
				this.$parent = window.$(ref);
			}} className="wetv_bg">
				<Header/>
				<div className="flex row padded center-xs">
					<div className="col-xs-16 col-lg-6 padded">
						<div className="body-content" style={{paddingBottom: 0}}
						     ref={(ref) => {
							     this.$content = window.$(ref);
						     }}>
							{
								this.state.form_verify_captcha ?
									<form className={"ui form segment"}
									      ref={(ref) => {

										      let post_url = window.host + '/api/v1/e-commerce/submit_confirm_email';
										      window.$(ref).form2('setup');
										      window.$(ref).form2('submit', {
											      method: 'POST',
											      url: post_url,
											      beforeSent: (object) => {
												      // console.log(object);
												      return object;
											      },
											      loadingButton: this.$content.find('form').find('button.button.approve'),
											      onSuccess: (response) => {
												      // console.log(response);
												      if (response.success) {
													      this.setState({
														      form_message: response.message,
														      form_verify_captcha: false,
													      })
												      }
												      if (response.error) {
													      // console.log(response.message);
													      this.setState({
														      form_verify_captcha: response.message[0].error_message,
													      });
												      }
											      }
										      });
									      }}
									>
										<h3 className={"ui header"}>ยืนยันอีเมล</h3>
										<p>{this.state.form_verify_captcha}</p>
										<input type={"hidden"} name={"token"}
										       value={window.Global.getAllQueryStrings().t}/>
										<div className="field GoogleRecaptcha_root"/>
										<div className="ui error message"/>
										<button type={"submit"}
										        ref={(ref) => {
											        this.submit_btn = window.$(ref);
										        }}
										        className={"ui button wetv-btn oval btn-lg approve"}>
											ยืนยัน
										</button>
									</form>
									:
									null
							}
							{
								this.state.form_message ?
									<>
										<div className="ui success icon message">
											<i className="check icon"/>
											<div className="content">
												<h1 className="ui header" style={{textAlign: 'left'}}>
													ยืนยันอีเมล
												</h1>
												<p>{this.state.form_message}</p>
											</div>
										</div>
										<div className="ui segment">
											<div className={"flex row"}>
												<Link to={'/payment?token=' + Global2.getMyProfile().token}
												      className={"ui button wetv-btn oval btn-lg"}>
													หน้าหลัก
												</Link>
												<div className={"right floated"}>
													<Link to={'/'} className={"ui button wetv-btn oval btn-lg"}>
														เข้าสู่ระบบ
													</Link>
												</div>
											</div>
										</div>
									</>
									: null
							}
							{
								!this.state.form_verify_captcha
								&& !this.state.form_message ?
									<div className="ui info icon message">
										<i className="notched circle loading icon"/>
										<div className="content">
											<h1 className="ui header" style={{textAlign: 'left'}}>
												ยืนยันอีเมล
											</h1>
											<p>ระบบกำลังทำการยืนยันอีเมล</p>
										</div>
									</div>
									: null
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
